import React, { FC, useEffect, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { capitalize } from 'lodash';
import { FormProvider, useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';
import * as yup from 'yup';

import Api from '~/Api';
import BackButton from '~/components/atoms/buttons/BackButton';
import Button from '~/components/atoms/buttons/Button';
import Loader from '~/components/atoms/Loader';
import HookFormSelectField from '~/components/hookFormControls/SelectFieldControl';
import HookFormTextField from '~/components/hookFormControls/TextFieldControl';
import { useModalContext } from '~/components/layoutComponents/modal/ModalContext';
import { useSnackBarContext } from '~/components/layoutComponents/snackbar/SnackbarContext';
import TopHeadlineRow from '~/components/molecules/TopHeadlineRow';
import { READABLE_DATE_TIME_FORMAT } from '~/constants/date';
import { emailSchema } from '~/helpers/validationSchemas';
import useIsMobile from '~/hooks/useIsMobile';
import { AdminsListRoute } from '~/modules/admins';
import UpdateAdminPasswordForm from '~/modules/admins/components/AdminItem/UpdateAdminPasswordForm';
import { ADMIN_STATUSES } from '~/modules/admins/constants';
import { AdminType } from '~/modules/admins/types';
import DateService from '~/services/Date';

import styles from './styles.module.scss';

type FormDataType = {
  email: string;
  status: ADMIN_STATUSES;
};

const formSchema = yup
  .object()
  .shape({
    email: yup
      .string()
      .required('E-mail address is required')
      .test('test-email', 'Invalid Email', (value) => {
        if (value) return emailSchema.isValidSync(value);
        return true;
      }),
    status: yup.string().required('Password is required'),
  })
  .required();

const AdminItem: FC = () => {
  const { handleOpenModal } = useModalContext();
  const [isFirstLoading, setIsFirstLoading] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [admin, setAdmin] = useState<AdminType>({
    id: '',
    email: '',
    status: ADMIN_STATUSES.DEACTIVATED,
    created_at: '2022-11-11 11:11:11',
    updated_at: '2022-11-11 11:11:11',
  });
  const isMobile = useIsMobile();
  const { showSnackbar } = useSnackBarContext();
  const history = useHistory();

  const { id } = useParams<{ id: string }>();

  const form = useForm<FormDataType>({
    resolver: yupResolver(formSchema),
    mode: 'onChange',
    defaultValues: {
      email: '',
      status: ADMIN_STATUSES.DEACTIVATED,
    },
  });

  const { handleSubmit, setValue } = form;

  const loadAdmin = async (id: string): Promise<void> => {
    try {
      setIsLoading(true);
      const admin = await Api.getAdmin(id);
      setAdmin(admin);
      setIsLoading(false);
    } catch (e: any) {
      showSnackbar(e.message);
      setIsLoading(false);
      history.push('/');
    }
  };

  const handleSuccessSubmit = async (data: FormDataType): Promise<void> => {
    // try {
    //   setIsLoading(true);
    //   const admin = await Api.updateAdmin(id, data);
    //   showSnackbar('Updated', 'success');
    //   setAdmin(admin);
    //   setIsLoading(false);
    // } catch (e: any) {
    //   showSnackbar(e.message);
    //   setIsLoading(false);
    //   history.push('/');
    // }
  };

  useEffect(() => {
    if (!id) {
      history.push('/');
      return;
    }
    loadAdmin(id).finally(() => setIsFirstLoading(false));
  }, [id]);

  const handleSetNewPassword = async (password: string): Promise<void> => {
    try {
      await Api.updateAdminPassword(id, password);
      showSnackbar('Password Updated', 'success');
    } catch (e: any) {
      showSnackbar(e.message);
      console.error(e);
    }
  };

  useEffect(() => {
    setValue('email', admin.email);
    setValue('status', admin.status);
  }, [admin]);

  return (
    <div>
      <BackButton
        className={styles.back_button}
        text="Back to Search"
        onClick={(): void => {
          history.push(AdminsListRoute.path);
        }}
      />
      <TopHeadlineRow className={styles.top_bar} text="Admin Card" />
      <div className={styles.container}>
        <Loader isLoading={isFirstLoading}>
          {/*<Tabs*/}
          {/*  className={styles.container__tabs}*/}
          {/*  items={[*/}
          {/*    {*/}
          {/*      label: 'General info',*/}
          {/*      isActive: true,*/}
          {/*    },*/}
          {/*  ]}*/}
          {/*  onClickItem={(): void => {}}*/}
          {/*/>*/}
          <div className={styles.form_container}>
            <FormProvider {...form}>
              <form>
                <div className={styles.form_container__field}>
                  <HookFormTextField
                    required
                    name="email"
                    label="Email"
                    disabled
                    fullWidth
                    size={isMobile ? 'small' : 'medium'}
                  />
                </div>
                <div className={styles.form_container__field}>
                  <HookFormSelectField
                    required
                    name="status"
                    label="Status"
                    disabled={isLoading}
                    fullWidth
                    size={isMobile ? 'small' : 'medium'}
                    options={Object.values(ADMIN_STATUSES).map((value) => ({
                      text: capitalize(value),
                      value,
                    }))}
                  />
                </div>

                <span
                  style={{ cursor: 'pointer', textDecoration: 'underline' }}
                  onClick={(): void => {
                    handleOpenModal({
                      component: ({ onClose }) => (
                        <UpdateAdminPasswordForm
                          setNewPassword={handleSetNewPassword}
                          onClose={onClose}
                        />
                      ),
                    });
                  }}
                >
                  Change password
                </span>

                <div className={styles.extra_info}>
                  <div className={styles.extra_info__title}>
                    <div>Created at:</div>
                    <div>Updated at:</div>
                  </div>
                  <div className={styles.extra_info__data}>
                    <div>
                      {DateService.format(
                        new Date(admin.created_at),
                        READABLE_DATE_TIME_FORMAT,
                      )}
                    </div>
                    <div>
                      {DateService.format(
                        new Date(admin.updated_at),
                        READABLE_DATE_TIME_FORMAT,
                      )}
                    </div>
                  </div>
                </div>

                <Button
                  type="submit"
                  disabled
                  color="primary"
                  variant="contained"
                  onClick={handleSubmit(handleSuccessSubmit)}
                >
                  Update info
                </Button>
              </form>
            </FormProvider>
          </div>
        </Loader>
      </div>
    </div>
  );
};

export default AdminItem;
