import React, { FC } from 'react';

import FormControlLabel, {
  FormControlLabelProps,
} from '@mui/material/FormControlLabel';
import { styled } from '@mui/material/styles';
import MuiSwitch, { SwitchProps as MuiSwitchProps } from '@mui/material/Switch';

import { colors } from '~/theme/colors';

const StyledSwitch = styled((props: MuiSwitchProps) => (
  <MuiSwitch
    focusVisibleClassName=".Mui-focusVisible"
    disableRipple
    {...props}
  />
))(({ theme }) => ({
  '&.MuiSwitch-root': {
    '& .MuiButtonBase-root': {
      padding: 0,
      transitionDuration: '300ms',
      color: colors.neutral_300,
      height: '100%',
      width: '100%',
    },

    '& .MuiSwitch-track': {
      backgroundColor: colors.neutral_600,
      opacity: 1,
      border: `2px solid ${colors.neutral_300}`,
      transition: theme.transitions.create(['background-color', 'border'], {
        duration: 300,
      }),
    },

    '& .Mui-checked': {
      color: colors.white,

      '& + .MuiSwitch-track': {
        backgroundColor: colors.primary_400,
        opacity: 1,
        border: '2px solid',
        borderColor: colors.primary_400,
      },
    },

    '& .Mui-disabled': {
      opacity: 0.5,

      '& + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
  },

  '&.MuiSwitch-sizeMedium': {
    width: 52,
    height: 32,
    padding: 0,

    '& .MuiSwitch-thumb': {
      boxSizing: 'border-box',
      width: 16,
      height: 16,
      transform: 'translate(-10px, 0px)',
      transition: theme.transitions.create(['transform', 'height', 'width'], {
        duration: 300,
      }),
    },

    '& .MuiSwitch-track': {
      borderRadius: 75,
    },

    '& .Mui-checked': {
      '& .MuiSwitch-thumb': {
        width: 20,
        height: 20,
      },
    },
  },
}));

const StyledFormControlLabel = styled((props: FormControlLabelProps) => (
  <FormControlLabel {...props} />
))(() => ({
  '&': {
    width: '100%',
    marginLeft: '0',
    justifyContent: 'space-between',
  },
  '& .MuiFormControlLabel-label': {
    color: colors.neutral_900,
    fontSize: '14px',
    fontWeight: 600,
    lineHeight: '20px',
  },
}));

export type SwitchProps = MuiSwitchProps & {
  label: string;
};

const Switch: FC<SwitchProps> = ({ label, ...props }) => {
  return (
    <StyledFormControlLabel
      labelPlacement="start"
      control={<StyledSwitch {...props} />}
      label={label}
    />
  );
};

export default Switch;
