/* eslint-disable @typescript-eslint/no-unused-vars */
import { FieldsError } from '~/Api/FieldsError';
import { MetaDataResponseType } from '~/Api/types';
import { ADMIN_STATUSES } from '~/modules/admins/constants';
import { AdminType } from '~/modules/admins/types';
import { ProductType, REGULARITY } from '~/modules/products/types';
import { UserType } from '~/modules/users/types';
import AbstractStorage from '~/services/storage/AbstractStorage';
import localStorage from '~/services/storage/localStorage';
import { SORT_BY, SORT_ORDER } from '~/types/common';
import { ProfileType } from '~/types/profile';

import { Api, RequestParams } from './generated-api/api';

export const BASE_API_URL = process.env.REST_API_BASE_URL || '/';

const mockProducts = [
  {
    created_at: '2024-01-01T00:00:00Z',
    is_unlimited: false,
    currency: 'USD',
    has_trial_period: false,
    id: '6e1e4f72-4e4f-4ca8-b10a-17c780d46447',
    is_solid: true,
    name: 'Basic One-Time Plan',
    price: 10,
    subscription_type: 'onetime',
    trial_amount: 0,
    trial_currency: 'USD',
    trial_period: 0,
    trial_words_amount: 0,
    updated_at: '2024-01-01T00:00:00Z',
    words_amount: 1000,
    regularity: REGULARITY.ONETIME,
    new_price_ui: 10,
    old_price_ui: 0,
    discount_percentage_ui: 0,
    regularity_ui: REGULARITY.ONETIME,
    description_ui: 'A basic one-time plan with 1000 words.',
    details_ui: 'Perfect for one-time projects or short-term needs.',
    is_popular_ui: false,
  },
  {
    created_at: '2024-01-01T00:00:00Z',
    is_unlimited: false,
    currency: 'EUR',
    has_trial_period: true,
    id: '225914ec-9e20-4baf-bcff-dc108b03dd4f',
    is_solid: true,
    name: 'Standard Weekly Plan',
    price: 15,
    subscription_type: 'week',
    trial_amount: 5,
    trial_currency: 'EUR',
    trial_period: 7,
    trial_words_amount: 1000,
    updated_at: '2024-01-01T00:00:00Z',
    words_amount: 5000,
    regularity: REGULARITY.WEEK,
    new_price_ui: 15,
    old_price_ui: 20,
    discount_percentage_ui: 25,
    regularity_ui: REGULARITY.WEEK,
    description_ui: 'A standard plan with 5000 words per week.',
    details_ui: 'Ideal for consistent weekly projects.',
    is_popular_ui: true,
  },
  {
    created_at: '2024-01-01T00:00:00Z',
    is_unlimited: true,
    currency: 'GBP',
    has_trial_period: true,
    id: '26490d09-9289-4082-9dda-111a9d7ffd37',
    is_solid: true,
    name: 'Premium Monthly Plan',
    price: 50,
    subscription_type: 'month',
    trial_amount: 10,
    trial_currency: 'GBP',
    trial_period: 30,
    trial_words_amount: 10000,
    updated_at: '2024-01-01T00:00:00Z',
    words_amount: 50000,
    regularity: REGULARITY.MONTH,
    new_price_ui: 50,
    old_price_ui: 60,
    discount_percentage_ui: 17,
    regularity_ui: REGULARITY.MONTH,
    description_ui: 'A premium plan with unlimited words per month.',
    details_ui: 'Perfect for high-demand monthly projects.',
    billing_period: 1,
    is_popular_ui: false,
  },
];

const baseApiClient = new Api({
  baseUrl: BASE_API_URL,
});

class AdminApi {
  static ACCESS_TOKEN_STORAGE_KEY = 'access_token';
  static REFRESH_TOKEN_STORAGE_KEY = 'refresh_token';

  accessToken: string | null;
  refreshToken: string | null;
  storage: AbstractStorage;

  constructor({
    accessToken,
    refreshToken,
    storage,
  }: {
    accessToken: string | null;
    refreshToken: string | null;
    storage: AbstractStorage;
  }) {
    this.accessToken = accessToken;
    this.refreshToken = refreshToken;
    this.storage = storage;
  }

  static async handleError(e: any, methodName): Promise<Error> {
    try {
      if (e.status === 404) {
        throw Error('Not found');
      }

      if (e.status >= 500) {
        throw Error('Server error');
      }

      if (e?.error?.fields) {
        return new FieldsError(e.error);
      }

      if (e?.error?.error) {
        return new Error(e.error.error);
      }

      if (e instanceof Response) {
        const parsedData = await e.json();

        if (parsedData.fields) {
          return new FieldsError(parsedData);
        }

        if (parsedData.error) {
          return new Error(parsedData.error);
        }
      }

      throw Error('Error');
    } catch (e: any) {
      return new Error(e.message || `Error in ${methodName}`);
    }
  }

  private getAuthHeaders(): Record<string, string> {
    if (this.accessToken === null) {
      this.clearAccessKeys();
      throw Error('Not authorized');
    }
    return {
      'Access-Token': this.accessToken,
    };
  }
  private setAccessKeys(access_token: string, refresh_token: string): string {
    this.accessToken = access_token;
    this.refreshToken = refresh_token;
    this.storage.setByKey(AdminApi.ACCESS_TOKEN_STORAGE_KEY, access_token);
    this.storage.setByKey(AdminApi.REFRESH_TOKEN_STORAGE_KEY, refresh_token);
    return access_token;
  }

  private clearAccessKeys(): void {
    this.accessToken = null;
    this.refreshToken = null;
    this.storage.removeByKey(AdminApi.ACCESS_TOKEN_STORAGE_KEY);
    this.storage.removeByKey(AdminApi.REFRESH_TOKEN_STORAGE_KEY);
  }

  private async makeAuthorizedRequest<T>(
    requestFunction: (params: RequestParams) => Promise<T>,
    methodName: string,
  ): Promise<T> {
    try {
      try {
        return await requestFunction({ headers: this.getAuthHeaders() });
      } catch (e: any) {
        if (e?.status === 403 || e?.status === 401) {
          try {
            await this.updateTokens();
          } catch (e: any) {
            if (e.message === 'Not authorized') {
              await this.clearAccessKeys();
              window.location.reload();
            }
          }
          return await requestFunction({ headers: this.getAuthHeaders() });
        }
        throw e;
      }
    } catch (e) {
      throw await AdminApi.handleError(e, methodName);
    }
  }

  // static mapAdminResponse = (admin: ModelsAdmin): AdminType => {
  //   try {
  //     return {
  //       id: admin.id || '',
  //       email: admin.email || '',
  //       status: ADMIN_STATUSES.ACTIVE,
  //       created_at: admin.created_at || '2021-01-01',
  //       updated_at: admin.updated_at || '2021-01-01',
  //     };
  //   } catch (e) {
  //     console.error(e);
  //     throw Error('Invalid response');
  //   }
  // };

  static mapUserResponse = (user: Record<string, any>): UserType => {
    try {
      return {
        id: user.id ?? '',
        email: user.email ?? '',
        status: user.email ?? '',
        panda_id: user.panda_id ?? '',
        panda_id_ios: user.panda_id_ios ?? '',
        created_at: user.created_at ?? '2021-01-01',
        updated_at: user.created_at ?? '2021-01-01',
      };
    } catch (e) {
      console.error(e);
      throw Error('Invalid response');
    }
  };

  static mapProductResponse = (product: Record<string, any>): ProductType => {
    try {
      return {
        id: product.id ?? '',
        subscription_type: product.subscription_type ?? '',
        is_unlimited: product.is_unlimited ?? false,
        currency: product.currency ?? 'USD',
        has_trial_period: product.has_trial_period ?? false,
        is_solid: product.is_solid ?? false,
        name: product.name ?? '',
        price: product.price ?? 0,
        trial_amount: product.trial_amount ?? 0,
        trial_currency: product.trial_currency ?? 'USD',
        trial_period: product.trial_period ?? 0,
        trial_words_amount: product.trial_words_amount ?? 0,
        words_amount: product.words_amount ?? 0,
        regularity: product.regularity ?? REGULARITY.ONETIME,
        billing_period: product.billing_period ?? undefined,
        new_price_ui: product.new_price_ui ?? 0,
        old_price_ui: product.old_price_ui ?? 0,
        discount_percentage_ui: product.discount_percentage_ui ?? 0,
        regularity_ui: product.regularity_ui ?? REGULARITY.ONETIME,
        description_ui: product.description_ui ?? '',
        details_ui: product.details_ui ?? '',
        is_popular_ui: product.is_popular_ui ?? false,
        updated_at: product.updated_at ?? '2021-01-01T00:00:00Z',
        created_at: product.created_at ?? '2021-01-01T00:00:00Z',
      };
    } catch (e) {
      console.error(e);
      throw Error('Invalid response');
    }
  };

  async updateTokens(): Promise<void> {
    try {
      if (this.refreshToken && this.accessToken) {
        // const { data } = await baseApiClient.v1.refreshTokenCreate({
        //   refresh_token: this.refreshToken,
        //   access_token: this.accessToken,
        // });

        const { data } = await baseApiClient.api.v1AuthRefreshTokenCreate({
          refresh_token: this.refreshToken,
          access_token: this.accessToken,
        });

        if (data.data?.access_token && data.data?.refresh_token) {
          const { access_token, refresh_token } = data.data;
          this.setAccessKeys(access_token, refresh_token);
        }
      }
    } catch (e) {
      console.error(e);
      this.clearAccessKeys();
      throw Error('Not authorized');
    }
  }

  async signIn(email: string, password: string): Promise<void> {
    try {
      // const { data } = await baseApiClient.adminPanel.loginCreate({
      //   email: email.trim(),
      //   password,
      // });

      const { data } = await baseApiClient.api.v1AuthLoginCreate({
        email: email.trim(),
        password,
      });

      if (data.data?.access_token && data.data?.refresh_token) {
        const { access_token, refresh_token } = data.data;
        this.setAccessKeys(access_token, refresh_token);
        return;
      }
    } catch (error) {
      throw await AdminApi.handleError(error, 'signIn');
    }
  }

  async signUp(params: {
    email: string;
    password: string;
    name: string;
  }): Promise<void> {
    try {
      // await baseApiClient.adminPanel.signUpCreate({
      //   ...params,
      //   email: params.email.trim(),
      // });
      const { data } = await baseApiClient.api.v1AuthSignUpCreate({
        ...params,
        email: params.email.trim(),
      });
    } catch (error) {
      throw await AdminApi.handleError(error, 'signUp');
    }
  }

  async forgotPassword(email: string): Promise<void> {
    // try {
    //   await baseApiClient.adminPanel.forgotPasswordCreate({
    //     email: email.trim(),
    //   });
    // } catch (error) {
    //   throw await AdminApi.handleError(error, 'forgotPassword');
    // }
  }

  async resetPassword(token: string, password: string): Promise<void> {
    // try {
    //   await baseApiClient.adminPanel.resetPasswordCreate({
    //     token,
    //     password,
    //   });
    // } catch (error) {
    //   throw await AdminApi.handleError(error, 'resetPassword');
    // }
  }

  async signOut(): Promise<void> {
    return this.makeAuthorizedRequest<void>(async (params) => {
      try {
        // await baseApiClient.adminPanel.logoutCreate(params);
        await baseApiClient.api.v1AuthLogoutCreate(params);
        this.clearAccessKeys();
      } catch (e) {
        throw await AdminApi.handleError(e, 'signOut');
      }
    }, 'signOut');
  }

  async getProfile(): Promise<ProfileType> {
    return this.makeAuthorizedRequest<ProfileType>(async (params) => {
      // const { data } = await baseApiClient.adminPanel.adminsProfileList(params);
      const { data } = await baseApiClient.api.v1ProfileList(params);
      if (data.data) {
        return {
          id: data.data.id || '',
          email: data.data.email || '',
        };
      }
      throw Error('Invalid response');
    }, 'getProfile');
  }

  async createAdmin(email: string, password: string): Promise<void> {
    return this.makeAuthorizedRequest<void>(async (params) => {
      // await baseApiClient.adminPanel.signUpCreate({ email, password }, params);
    }, 'createAdmin');
  }

  async updateAdminPassword(id: string, password: string): Promise<void> {
    return this.makeAuthorizedRequest<void>(async (params) => {
      // await baseApiClient.adminPanel.adminsInviteCreate({ email }, params); // TODO: uncomment when BE will be ready
    }, 'updateAdminPassword');
  }

  async getAdmin(id: string): Promise<AdminType> {
    return this.makeAuthorizedRequest<AdminType>(async (params) => {
      // const { data } = await baseApiClient.adminPanel.adminsDetail(id, params);
      // if (data.data) {
      //   return AdminApi.mapAdminResponse(data.data);
      // }
      throw Error('Invalid response');
    }, 'getAdmin');
  }

  async updateAdmin(
    id: string,
    dataToUpdate: Partial<AdminType>,
  ): Promise<void> {}

  async getAdmins({
    query,
  }: {
    query: {
      email?: string;
      name?: string;
      status?: ADMIN_STATUSES;
      sort_type: SORT_ORDER;
      sort_by: 'email' | 'name' | 'created_at' | 'updated_at';
      page: number;
      'per-page': number;
    };
  }): Promise<{
    data: AdminType[];
    meta: MetaDataResponseType;
  }> {
    return this.makeAuthorizedRequest<{
      data: AdminType[];
      meta: MetaDataResponseType;
    }>(async (params) => {
      // const { data } = await baseApiClient.adminPanel.adminsList(query, params);
      //
      // if (data && data.data && data.meta) {
      //   const users = data.data;
      //   const meta = data.meta;
      //   return {
      //     data: users.map((item) => AdminApi.mapAdminResponse(item)),
      //     meta: {
      //       currentPage: meta.current_page ?? 1,
      //       pageSize: meta.page_size ?? 10,
      //       totalCount: meta.total_count ?? 0,
      //       totalPages: meta.total_pages ?? 1,
      //     },
      //   };
      // }
      throw Error('Invalid response');
    }, 'getAdmins');
  }

  async getProduct(id: string): Promise<ProductType> {
    return this.makeAuthorizedRequest<ProductType>(async (params) => {
      // const { data } = await baseApiClient.adminPanel.productsDetail(
      //   id,
      //   params,
      // );
      const data = {
        data: mockProducts.find((pr) => pr.id === id) || mockProducts[0],
      };
      return AdminApi.mapProductResponse(data.data);
    }, 'getProduct');
  }

  async getProducts({
    query,
  }: {
    query: {
      sort_type: SORT_ORDER;
      sort_by: 'created_at' | 'updated_at';
      page: number;
      'per-page': number;
    };
  }): Promise<{
    data: ProductType[];
    meta: MetaDataResponseType;
  }> {
    return this.makeAuthorizedRequest<{
      data: ProductType[];
      meta: MetaDataResponseType;
    }>(async (params) => {
      // const { data } = await baseApiClient.adminPanel.productsList(
      //   query,
      //   params,
      // );
      //
      // if (!data.data || !data.meta) {
      //   return {
      //     data: [],
      //     meta: {
      //       currentPage: 1,
      //       pageSize: 20,
      //       totalCount: 0,
      //       totalPages: 1,
      //     },
      //   };
      // }
      //

      const data = {
        data: mockProducts,
        meta: { current_page: 1, page_size: 1, total_count: 1, total_pages: 1 },
      };
      const products = data.data;
      const meta = data.meta;

      return {
        data: products.map((item) => AdminApi.mapProductResponse(item)),
        meta: {
          currentPage: meta.current_page ?? 1,
          pageSize: meta.page_size ?? 10,
          totalCount: meta.total_count ?? 0,
          totalPages: meta.total_pages ?? 1,
        },
      };
    }, 'getProducts');
  }

  async updateProduct(
    id: string,
    dataToUpdate: Partial<ProductType>,
  ): Promise<ProductType> {
    return this.makeAuthorizedRequest<ProductType>(async (params) => {
      // const { data } = await baseApiClient.adminPanel.productsUpdate(
      //   id,
      //   {
      //     ...dataToUpdate,
      //     ...(dataToUpdate.product_name
      //       ? { name: dataToUpdate.product_name }
      //       : {}),
      //   },
      //   params,
      // );
      const data = { data: {} };
      return AdminApi.mapProductResponse(data.data);
    }, 'updateProduct');
  }

  async createProduct(dataToUpdate: Record<string, any>): Promise<ProductType> {
    return this.makeAuthorizedRequest<ProductType>(async (params) => {
      // const { data } = await baseApiClient.adminPanel.productsCreate(
      //   {
      //     ...dataToUpdate,
      //     ...(dataToUpdate.product_name
      //       ? { name: dataToUpdate.product_name }
      //       : {}),
      //   },
      //   params,
      // );
      const data = { data: {} };

      return AdminApi.mapProductResponse(data.data);
    }, 'updateProduct');
  }

  async deleteProduct(id: string): Promise<void> {
    // return this.makeAuthorizedRequest<void>(async (params) => {
    //   await baseApiClient.adminPanel.prod(id, params);
    // }, 'deleteProduct');
  }

  async uploadMediaFile(file: File): Promise<string> {
    return 'https://images.freeimages.com/images/previews/ac9/railway-hdr-1361893.jpg';
  }

  async removeFile(): Promise<void> {
    return this.makeAuthorizedRequest<void>(async (params) => {
      // await baseApiClient.coach.profileAvatarDelete(params);
    }, 'removeFile');
  }

  async getUsers({
    query,
  }: {
    query: {
      sort_type: SORT_ORDER;
      sort_by: 'created_at' | 'updated_at';
      page: number;
      'per-page': number;
    };
  }): Promise<{
    data: UserType[];
    meta: MetaDataResponseType;
  }> {
    return this.makeAuthorizedRequest<{
      data: UserType[];
      meta: MetaDataResponseType;
    }>(async (params) => {
      // const { data } = await baseApiClient.adminPanel.usersList(query, params);
      //
      // if (!data.data || !data.meta) {
      //   return {
      //     data: [],
      //     meta: {
      //       currentPage: 1,
      //       pageSize: 20,
      //       totalCount: 0,
      //       totalPages: 1,
      //     },
      //   };
      // }

      const data = {
        data: [],
        meta: { current_page: 1, page_size: 1, total_count: 1, total_pages: 1 },
      };
      const users = data.data;
      const meta = data.meta;

      return {
        data: users.map((item) => AdminApi.mapUserResponse(item)),
        meta: {
          currentPage: meta.current_page ?? 1,
          pageSize: meta.page_size ?? 10,
          totalCount: meta.total_count ?? 0,
          totalPages: meta.total_pages ?? 1,
        },
      };
    }, 'getUsers');
  }
}

const accessToken = localStorage.getByKey(AdminApi.ACCESS_TOKEN_STORAGE_KEY);
const refreshToken = localStorage.getByKey(AdminApi.REFRESH_TOKEN_STORAGE_KEY);

export default new AdminApi({
  accessToken,
  refreshToken,
  storage: localStorage,
});
