import React from 'react';

import { Icon } from '@iconify/react';

import router from '~/core/router';
import sidebar from '~/core/sidebar';
import { withSidebarLayout } from '~/decorators/withLayout';
import ProductCreate from '~/modules/products/components/ProductCreate';
import ProductItem from '~/modules/products/components/ProductItem';
import ProductList from '~/modules/products/components/ProductsList';
import { colors } from '~/theme/colors';

const permissionKey = 'PRODUCTS';

export const ProductsListRoute = {
  path: '/product',
  Component: withSidebarLayout(ProductList),
  title: 'Products',
  exact: true,
  permissionKey,
};

export const ProductsItemRoute = {
  path: '/product/:id',
  Component: withSidebarLayout(ProductItem),
  title: 'Product Card',
  exact: true,
  permissionKey,
};

export const ProductsCreateRoute = {
  path: '/product/new',
  Component: withSidebarLayout(ProductCreate),
  title: 'Product Create',
  exact: true,
  permissionKey,
};

sidebar.addItems({
  b: [
    {
      path: '/product',
      title: 'Products',
      Icon: ({ isActive }) => (
        <Icon
          icon="eos-icons:products"
          width="20"
          color={isActive ? colors.white : colors.primary_400}
        />
      ),
      permissionKey,
    },
  ],
});

router.addPrivateRoutes([
  ProductsListRoute,
  ProductsCreateRoute,
  ProductsItemRoute,
]);
