import React, { FC } from 'react';

import { TableRow as MuiTableRow, styled, TableRowProps } from '@mui/material';

const TableRowStyled = styled(MuiTableRow)(({ theme }) => ({
  '&': {
    background: theme.colors['neutral_50'],
    border: `1px solid ${theme.colors['neutral_100']}`,
    transition: 'background 250ms',

    '&:last-child': {
      borderRadius: '0px 0px 8px 8px',
    },

    '&:hover': {
      background: theme.colors['primary_800'],
    },
  },

  '.MuiTableCell-body': {
    '&:first-child': {
      paddingLeft: '24px',
    },

    '&:last-child': {
      paddingRight: '24px',
    },
  },
}));

const TableRow: FC<TableRowProps> = ({ children, ...props }) => {
  return <TableRowStyled {...props}>{children}</TableRowStyled>;
};

export default TableRow;
