// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".y__qecAkAHFHrjytDSuw{display:flex;flex-grow:1;width:100%;position:relative}.NhILVkJl9rnxmzYtqQdE{display:inline-block;position:relative;z-index:1}.NhILVkJl9rnxmzYtqQdE:not(:last-child){margin-right:10px}.hMTXxB1tofBHplouL6Mw{height:100%;width:100%;white-space:nowrap;overflow-y:scroll;scrollbar-width:none;-ms-overflow-style:none}.hMTXxB1tofBHplouL6Mw::-webkit-scrollbar{display:none}.y__qecAkAHFHrjytDSuw::after{content:\"\";display:block;border-bottom:1px solid #e5e6e9;position:absolute;bottom:0;left:0;width:100%}", "",{"version":3,"sources":["webpack://./src/components/molecules/Tabs/styles.module.scss"],"names":[],"mappings":"AAGA,sBACE,YAAA,CACA,WAAA,CACA,UAAA,CACA,iBAAA,CAEA,sBACE,oBAAA,CACA,iBAAA,CACA,SAAA,CAEA,uCACE,iBAAA,CAIJ,sBACE,WAAA,CACA,UAAA,CACA,kBAAA,CACA,iBAAA,CACA,oBAAA,CACA,uBAAA,CAEA,yCACE,YAAA,CAIJ,6BACE,UAAA,CACA,aAAA,CACA,+BAAA,CACA,iBAAA,CACA,QAAA,CACA,MAAA,CACA,UAAA","sourcesContent":["@import 'src/styles/breakpoints';\n@import 'src/styles/colors';\n\n.container {\n  display: flex;\n  flex-grow: 1;\n  width: 100%;\n  position: relative;\n\n  &__tab_container {\n    display: inline-block;\n    position: relative;\n    z-index: 1;\n\n    &:not(:last-child) {\n      margin-right: 10px;\n    }\n  }\n\n  &__inner_wrapper {\n    height: 100%;\n    width: 100%;\n    white-space: nowrap;\n    overflow-y: scroll;\n    scrollbar-width: none;\n    -ms-overflow-style: none;\n\n    &::-webkit-scrollbar {\n      display: none;\n    }\n  }\n\n  &::after {\n    content: '';\n    display: block;\n    border-bottom: 1px solid $neutral-200;\n    position: absolute;\n    bottom: 0;\n    left: 0;\n    width: 100%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "y__qecAkAHFHrjytDSuw",
	"container__tab_container": "NhILVkJl9rnxmzYtqQdE",
	"container__inner_wrapper": "hMTXxB1tofBHplouL6Mw"
};
export default ___CSS_LOADER_EXPORT___;
