import React, { FC, useEffect } from 'react';

import clsx from 'clsx';

import Pagination from '~/components/molecules/Pagination';
import TopHeadlineRow from '~/components/molecules/TopHeadlineRow';
import TotalCount from '~/components/molecules/TotalCount';
import useFilters from '~/hooks/useFilters';
import useQueryParams from '~/hooks/useQueryParams';
import Table from '~/modules/users/components/UsersList/Table';
import useData, {
  FiltersType,
} from '~/modules/users/components/UsersList/useData';
import { SORT_ORDER } from '~/types/common';

import styles from './styles.modules.scss';

const DEFAULT_FILTER_STATE: FiltersType = {
  sortOrder: SORT_ORDER.DESC,
  sortBy: 'created_at',
  page: 1,
  'per-page': 10,
};

const UsersList: FC = () => {
  const { getQueryParams, setQueryParams } = useQueryParams();

  const defaultFilterState = {
    ...DEFAULT_FILTER_STATE,
    ...getQueryParams(),
  };

  const { filters, updateFilter } = useFilters<FiltersType>(defaultFilterState);
  const { users, isLoading, meta } = useData(filters);

  useEffect(() => {
    setQueryParams(filters);
  }, [filters]);

  return (
    <div
      className={clsx(
        { [styles.loading]: isLoading },
        styles.vertical_flex_container,
        styles['vertical_flex_container--full_height'],
      )}
    >
      <TopHeadlineRow className={styles.top_bar} text="Users" />
      <div
        className={clsx(
          styles.container,
          styles.vertical_flex_container__full_parent_height,
        )}
      >
        <div className={styles.container__left}>
          <TotalCount count={meta.totalCount} />
          <div
            className={clsx(
              styles.vertical_flex_container,
              styles['vertical_flex_container--full_height'],
            )}
          >
            <div className={styles.vertical_flex_container__full_parent_height}>
              <Table
                users={users}
                filter={{
                  sortBy: filters.sortBy,
                  sortOrder: filters.sortOrder,
                }}
                applyFilters={(filters): void => updateFilter(filters)}
              />
            </div>
            {meta.totalPages > 1 && (
              <Pagination
                currentPage={Number(meta.currentPage)}
                totalPages={Number(meta.totalPages)}
                setPage={(page: number): void => {
                  updateFilter({ page });
                }}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default UsersList;
