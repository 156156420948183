import { TextField as MuiTextField, styled } from '@mui/material';

export const StyledTextField = styled(MuiTextField, {
  name: 'StyledTextField',
})(({ size, color, label, theme }) => ({
  '&': {
    paddingTop: label ? '20px' : '0',
  },

  '& .MuiFormHelperText-root': {
    margin: '5px 0 0',
    color:
      color === 'success'
        ? theme.colors['primary_500']
        : color === 'error'
        ? theme.colors['error_500']
        : theme.colors['black'],
  },

  '& .Mui-focused': {
    color: theme.colors['black'],
  },

  '& .MuiInputBase-root': {
    borderRadius: '8px',

    '&:hover fieldset': {
      borderColor: theme.colors['neutral_800'],
    },

    '& fieldset': {
      top: label ? '-5px' : '0px',
      borderColor: theme.colors['neutral_100'],
    },

    '&.Mui-error': {
      backgroundColor: theme.colors['error_50'],
    },

    '&.Mui-focused': {
      backgroundColor: theme.colors['neutral_2_50'],
      '& fieldset': {
        borderColor: theme.colors['neutral_700'],
        borderWidth: '1px',
      },
    },

    '&.MuiInputBase-colorSuccess': {
      '& fieldset': {
        borderColor: theme.colors['primary_500'],
      },

      '& input': {
        backgroundColor: theme.colors['success_50'],
      },
    },

    '& .MuiInputBase-colorError': {
      '& fieldset': {
        borderColor: theme.colors['error_500'],
      },

      '& input': {
        backgroundColor: theme.colors['error_50'],
      },
    },

    '&.Mui-disabled': {
      color: theme.colors['neutral_100'],
      backgroundColor: theme.colors['neutral_2_50'],

      '& fieldset': {
        borderColor: theme.colors['neutral_100'],
      },
    },
  },

  '& .MuiInputBase-input': {
    fontSize: '15px',
    fontWeight: 400,
    lineHeight: '24px',
    borderColor: theme.colors['neutral_100'],
    padding: '12px 16px',
  },

  '& .MuiInputLabel-root': {
    color: theme.colors['neutral_800'],
    fontSize: '14px',
    transform:
      size === 'medium'
        ? 'matrix(1, 0, 0, 1, 16, 35)'
        : 'matrix(1, 0, 0, 1, 16, 29)',

    '&.MuiInputLabel-shrink': {
      transform: 'matrix(1, 0, 0, 1, 0, 0)',
    },
  },

  '& .MuiInputBase-root legend > span': {
    display: 'none',
  },

  '& .MuiInputBase-multiline': {
    padding: '0',
  },

  '& .MuiInputBase-multiline.MuiInputBase-sizeSmall textarea': {
    height: '40px',
    padding: '8px 16px',
    fontSize: '14px',
  },

  '& .MuiInputBase-sizeSmall': {
    '& input': {
      padding: '9px 16px',
      fontSize: '14px',
      lineHeight: '22px',
      height: '40px',
      boxSizing: 'border-box',
    },
  },
}));
