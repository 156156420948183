// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".txIJc1cD9Vq5J7TJbPo2{display:flex;justify-content:space-between;align-items:center;min-height:120px;padding:32px 40px}@media(max-width: 768px){.txIJc1cD9Vq5J7TJbPo2{min-height:72px;padding:8px 12px}}.Fc_9jUT5_WZWlVbw9vGL{font-size:32px;font-weight:600;font-family:\"Source Serif Pro\",sans-serif}@media(max-width: 768px){.Fc_9jUT5_WZWlVbw9vGL{font-size:24px}}", "",{"version":3,"sources":["webpack://./src/components/molecules/TopHeadlineRow/styles.module.scss","webpack://./src/styles/_breakpoints.scss","webpack://./src/styles/_fonts.scss"],"names":[],"mappings":"AAGA,sBACE,YAAA,CACA,6BAAA,CACA,kBAAA,CACA,gBAAA,CACA,iBAAA,CCNE,yBDCJ,sBAQI,eAAA,CACA,gBAAA,CAAA,CAGF,sBACE,cAAA,CACA,eAAA,CACA,yCElBmB,CDEnB,yBDaF,sBAMI,cAAA,CAAA","sourcesContent":["@import \"src/styles/fonts\";\n@import \"src/styles/breakpoints\";\n\n.container {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  min-height: 120px;\n  padding: 32px 40px;\n\n  @include breakpoint(xs) {\n    min-height: 72px;\n    padding: 8px 12px;\n  }\n\n  &__text {\n    font-size: 32px;\n    font-weight: 600;\n    font-family: $headings-font-family;\n\n    @include breakpoint(xs) {\n      font-size: 24px;\n    }\n  }\n}\n","@mixin breakpoint($class) {\n  @if $class == xs {\n    @media (max-width: 768px) {\n      @content;\n    }\n  }\n\n  @if $class == md {\n    @media (max-width: 992px) {\n      @content;\n    }\n  }\n\n  @if $class == xl {\n    @media (min-width: 1536px) {\n      @content;\n    }\n  }\n}\n","$headings-font-family: 'Source Serif Pro', sans-serif;\n$content-font-family: 'Source Sans Pro', sans-serif;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "txIJc1cD9Vq5J7TJbPo2",
	"container__text": "Fc_9jUT5_WZWlVbw9vGL"
};
export default ___CSS_LOADER_EXPORT___;
