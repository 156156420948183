// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".DwMH3QkBW0G7XRTXW9R2{padding:12px 40px}.TXnRW6kF28tFJ4_bgcSP{padding:0 40px}.ajRoqjLLaRlC6aRsKNXC{margin-bottom:40px}.kV_qi84_PvhUqKVY0LoK{max-width:500px;margin-bottom:48px;width:100%;display:flex;flex-direction:column;flex-wrap:wrap;gap:24px}.KGnJwVTWbXfrdT323MgB{position:relative}.qGAysroTd337LJljdlsk{position:absolute;top:-14px;right:0;font-size:14px}.nDXlrCNh_234_nW3_J1N{display:flex;gap:16px;flex-wrap:nowrap}", "",{"version":3,"sources":["webpack://./src/modules/products/components/ProductCreate/styles.module.scss"],"names":[],"mappings":"AAEA,sBACE,iBAAA,CAGF,sBACE,cAAA,CAEA,sBACE,kBAAA,CAKF,sBACE,eAAA,CACA,kBAAA,CACA,UAAA,CACA,YAAA,CACA,qBAAA,CACA,cAAA,CACA,QAAA,CAGF,sBACE,iBAAA,CAGF,sBACE,iBAAA,CACA,SAAA,CACA,OAAA,CACA,cAAA,CAIJ,sBACE,YAAA,CACA,QAAA,CACA,gBAAA","sourcesContent":["@import \"src/styles/colors\";\n\n.back_button {\n  padding: 12px 40px;\n}\n\n.container {\n  padding: 0 40px;\n\n  &__tabs {\n    margin-bottom: 40px;\n  }\n}\n\n.form {\n  &__fieldset {\n    max-width: 500px;\n    margin-bottom: 48px;\n    width: 100%;\n    display: flex;\n    flex-direction: column;\n    flex-wrap: wrap;\n    gap: 24px;\n  }\n\n  &__field {\n    position: relative;\n  }\n\n  &__optional_label {\n    position: absolute;\n    top: -14px;\n    right: 0;\n    font-size: 14px;\n  }\n}\n\n.buttons_container {\n  display: flex;\n  gap: 16px;\n  flex-wrap: nowrap;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"back_button": "DwMH3QkBW0G7XRTXW9R2",
	"container": "TXnRW6kF28tFJ4_bgcSP",
	"container__tabs": "ajRoqjLLaRlC6aRsKNXC",
	"form__fieldset": "kV_qi84_PvhUqKVY0LoK",
	"form__field": "KGnJwVTWbXfrdT323MgB",
	"form__optional_label": "qGAysroTd337LJljdlsk",
	"buttons_container": "nDXlrCNh_234_nW3_J1N"
};
export default ___CSS_LOADER_EXPORT___;
