import React from 'react';

import { Icon } from '@iconify/react';

import router from '~/core/router';
import sidebar from '~/core/sidebar';
import { withSidebarLayout } from '~/decorators/withLayout';
import AdminItem from '~/modules/admins/components/AdminItem';
import AdminsList from '~/modules/admins/components/AdminsList';
import { colors } from '~/theme/colors';

const permissionKey = 'ADMINS';

export const AdminsListRoute = {
  path: '/admin',
  Component: withSidebarLayout(AdminsList),
  title: 'Admins',
  exact: true,
  permissionKey,
};

export const AdminsItemRoute = {
  path: '/admin/:id',
  Component: withSidebarLayout(AdminItem),
  title: 'Admin Card',
  exact: true,
  permissionKey,
};

sidebar.addItems({
  b: [
    {
      path: '/admin',
      title: 'Admins',
      Icon: ({ isActive }) => (
        <Icon
          icon="ri:admin-fill"
          width="20"
          color={isActive ? colors.white : colors.primary_400}
        />
      ),
      permissionKey,
    },
  ],
});

router.addPrivateRoutes([AdminsListRoute, AdminsItemRoute]);
