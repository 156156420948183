/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface BunNullTime {
  'time.Time'?: string;
}

export enum BypassgptModelType {
  ModelTypeFast = 'Fast',
  ModelTypeCreative = 'Creative',
  ModelTypeEnhanced = 'Enhanced',
}

export interface EntitiesGetPricesResponse {
  data?: EntitiesPriceData[];
  pagination?: EntitiesPagination;
}

export interface EntitiesInitPaymentPaypalResponse {
  order?: EntitiesInitPaymentPaypalResponseOrder;
  order_metadata?: EntitiesInitPaymentPaypalResponseOrderMetadata;
  pay_form?: EntitiesInitPaymentPaypalResponsePayForm;
}

export interface EntitiesInitPaymentPaypalResponseOrder {
  amount?: number;
  created_at?: string;
  currency?: string;
  customer_email?: string;
  ip_address?: string;
  method?: string;
  order_desc?: string;
  order_id?: string;
  status?: string;
  updated_at?: string;
}

export type EntitiesInitPaymentPaypalResponseOrderMetadata = Record<
  string,
  any
>;

export interface EntitiesInitPaymentPaypalResponsePayForm {
  script_url?: string;
  token?: string;
}

export interface EntitiesMerchantData {
  merchant?: string;
  payment_intent?: string;
  signature?: string;
}

export interface EntitiesPagination {
  limit?: number;
  offset?: number;
  total_count?: number;
}

export interface EntitiesPriceData {
  country?: string;
  created_at?: string;
  currency?: string;
  default?: boolean;
  id?: string;
  product_price?: number;
  status?: string;
  trial_price?: number;
  updated_at?: string;
}

export type FrontdefABTestsPresenter = Record<string, string>;

export interface FrontdefProduct {
  alternative_non_trial_name?: string;
  bi_is_upsell?: boolean;
  billing_period?: number;
  created_at?: string;
  currency?: string;
  description?: string;
  description_ui?: string;
  details_ui?: string;
  discount_percentage_ui?: number;
  has_trial_period?: boolean;
  id?: string;
  is_popular_ui?: boolean;
  is_solid?: boolean;
  is_unlimited?: boolean;
  name?: string;
  new_price_ui?: number;
  old_price_ui?: number;
  price?: number;
  regularity?: FrontdefRegularity;
  regularity_ui?: string;
  solid_name_for_bi?: string;
  trial_amount?: number;
  trial_period?: number;
  trial_words_amount?: number;
  updated_at?: string;
  words_amount?: number;
}

export enum FrontdefRegularity {
  RegularityDaily = 'day',
  RegularityMonthly = 'month',
  RegularityYearly = 'year',
  RegularityWeekly = 'week',
  RegularityOnetime = 'onetime',
}

export enum FrontdefSubscriptionStatus {
  SubscriptionStatusActive = 'active',
  SubscriptionStatusCancelled = 'cancelled',
  SubscriptionStatusPaused = 'paused',
  SubscriptionStatusPausePending = 'pause_pending',
  SubscriptionStatusRedemption = 'redemption',
  SubscriptionStatusCancelPending = 'cancel_pending',
}

export interface FrontdefUserOnboardingV2 {
  ai_experience?: string;
  company_size?: string;
  created_at?: string;
  is_finished?: boolean;
  language?: string;
  position?: string;
  profession?: string;
  purposes?: string;
  updated_at?: string;
  user_id?: string;
}

export interface FrontdefUserSubscriptionPresenter {
  billing_period?: string;
  cancel_code?: string;
  cancel_for_change_subs?: boolean;
  cancel_for_change_subs_to_product_id?: string;
  cancel_message?: string;
  cancel_request_at?: string;
  cancelled_at?: string;
  created_at?: string;
  expired_at?: string;
  id?: string;
  is_current?: boolean;
  is_trial?: boolean;
  is_unlimited?: boolean;
  next_charge_at?: string;
  pause_from?: string;
  pause_to?: string;
  payment_type?: string;
  product_id?: string;
  response_count_total?: number;
  saved_time_minutes?: number;
  solid_event_id?: string;
  started_at?: string;
  status?: FrontdefSubscriptionStatus;
  subscription_name?: string;
  subscription_price?: number;
  updated_at?: string;
  user_id?: string;
  words_amount_left?: number;
  /** NON DB FIELDS */
  words_amount_total?: number;
  words_generated_total?: number;
}

export interface HandlersAIHumanizerExecutionReactionRequest {
  reaction?: number;
}

export interface HandlersAIHumanizerRequest {
  mode?: BypassgptModelType;
  original_text?: string;
  purpose?: UndetectablePurpose;
  readability?: UndetectableReadability;
}

export interface HandlersAiEditRequest {
  /** ‘summarize' | ‘improve’ | ‘simplify’ | ‘paraphrase’ | ‘informal_tone’ | 'formal_tone’ */
  action?: ModelsAiEditActionType;
}

export interface HandlersCancelCurrentSubscriptionRequest {
  feedback?: string;
  notification_agreement?: boolean;
  reason: string;
  related_to?: string;
  sub_reason?: string;
}

export interface HandlersChangePasswordRequest {
  new_password: string;
  old_password?: string;
}

export interface HandlersChatEventRequest {
  chat_id?: string;
  /**
   * @minLength 1
   * @maxLength 4000
   */
  message: string;
  message_context?: ModelsMessageContext;
  message_type?: ModelsMessageType;
  personality: ModelsChatPersonality;
  ui_prompt?: string;
  ui_response?: string;
}

export interface HandlersChatEventResponse {
  data?: any;
  event_type?: ModelsChatEvent;
}

export interface HandlersChromeExtensionRequest {
  /** ‘summarize' | ‘improve’ | ‘simplify’ | ‘paraphrase’ | ‘informal_tone’ | 'formal_tone’ */
  action?: ModelsChromeExtensionActionType;
  source_url?: string;
  text?: string;
}

export interface HandlersConfirmPasswordRequest {
  password?: string;
}

export interface HandlersCopyleaksAIDetectorRequest {
  file_content?: string;
  is_sandbox?: boolean;
}

export interface HandlersCopyleaksExecutionReactionRequest {
  reaction?: number;
}

export interface HandlersCopyleaksPlagiarismCheckRequest {
  file_content?: string;
  is_sandbox?: boolean;
}

export interface HandlersCreateChatFeedbackRequest {
  chat_id?: string;
  chat_understanding_rate?: number;
  contact_consent?: boolean;
  experience_rate?: number;
  favorite_chat_feature_aspect?: string;
  responsiveness_rate?: number;
  suggested_chat_improvements?: string;
}

export interface HandlersCreateChatRequest {
  chat_type?: ModelsChatType;
  id?: string;
  messages?: ModelsMessageOnInteractiveSearch[];
  name?: string;
  personality?: ModelsChatPersonality;
}

export interface HandlersCreateFunnelUserRequest {
  country?: string;
  email: string;
  funnel_emails?: string;
  url?: string;
  user_id?: string;
}

export interface HandlersCreateToolBoardFeedbackRequest {
  contact_consent?: boolean;
  wanted_tool_description?: string;
  wanted_tool_name?: string;
}

export interface HandlersCreateToolFeedbackRequest {
  contact_consent?: boolean;
  excited_about?: string;
  experience_rate?: number;
  responsiveness_rate?: number;
  suggested_improvements?: string;
  tool_understanding_rate?: number;
}

export interface HandlersForgotPasswordRequest {
  email: string;
}

export interface HandlersFunnelContactSupportRequest {
  email?: string;
  message?: string;
}

export interface HandlersFunnelUserPaymentUpsellRequest {
  fail_url?: string;
  force3ds?: boolean;
  success_url?: string;
}

export interface HandlersGenerateArticleOutlineRequest {
  topic?: string;
}

export interface HandlersGenerateExtendedArticleRequest {
  outline?: ModelsArticleOutline;
}

export interface HandlersGetProfileResponse {
  ab_tests?: FrontdefABTestsPresenter;
  avatar_url?: string;
  created_at?: string;
  email?: string;
  extra_fields?: number[];
  gclid?: string;
  id?: string;
  is_chrome_extension_installed?: boolean;
  is_onboarded?: boolean;
  is_test_user?: boolean;
  name?: string;
  product_id?: string;
  product_name?: string;
  /** NON DB FIELDS */
  response_count_total?: number;
  status?: string;
  subscription_status?: FrontdefSubscriptionStatus;
  updated_at?: string;
  user_features?: {
    detectors_enabled?: boolean;
    image_generation_enabled?: boolean;
  };
  user_onboarding?: FrontdefUserOnboardingV2;
  user_product?: FrontdefProduct;
  user_subscription?: FrontdefUserSubscriptionPresenter;
  was_in_redemption?: boolean;
  words_amount_left?: number;
}

export type HandlersHealthCheckResponse = Record<string, string>;

export interface HandlersImageGenerationPromptImproveRequest {
  instruction?: string;
}

export interface HandlersImageGenerationRequest {
  hd_quality?: boolean;
  image_size?: string;
  instruction?: string;
  style?: string;
}

export interface HandlersInsertBrandVoiceTestRequest {
  /** TestName   string `json:"test_name"` */
  input?: string;
  source_name?: string;
}

export interface HandlersLoginRequest {
  email?: string;
  password?: string;
}

export interface HandlersLoginResponse {
  access_token?: string;
  refresh_token?: string;
}

export interface HandlersOffboardingSupportMailRequest {
  comment?: string;
  desired_action?: string;
  offboarding_reason?: string;
}

export interface HandlersPauseCurrentSubscriptionRequest {
  feedback?: string;
  notification_agreement?: boolean;
  pause_amount: number;
  pause_unit: 'day' | 'week' | 'month';
  reason: string;
  related_to?: string;
  sub_reason?: string;
}

export interface HandlersPromptImproveRequest {
  prompt?: string;
}

export interface HandlersPromptImproveResponse {
  improved_prompt?: string;
}

export interface HandlersPromptSimpleRequest {
  prompt?: string;
}

export interface HandlersRefreshTokenRequest {
  access_token?: string;
  refresh_token?: string;
}

export interface HandlersRefreshTokenResponse {
  access_token?: string;
  refresh_token?: string;
}

export interface HandlersRegenerateAAGOutlineSectionRequest {
  outline?: ModelsArticleOutline;
  regenerate_index?: number;
}

export interface HandlersResetPasswordRequest {
  password: string;
  token?: string;
}

export interface HandlersSetImageGenerationReactionRequest {
  reaction?: number;
}

export interface HandlersSignUpRequest {
  email: string;
  extra_fields?: number[];
  name: string;
  password: string;
}

export interface HandlersSignUpResponse {
  access_token?: string;
  refresh_token?: string;
}

export interface HandlersSimplePromptResponse {
  data?: any;
  event_type?: ModelsChatEvent;
}

export interface HandlersToolEventResponse {
  data?: ModelsToolExecution;
  event_type?: ModelsChatEvent;
}

export interface HandlersToolExecutionReactionRequest {
  reaction?: number;
}

export interface HandlersUpdateChatFeedbackRequest {
  chat_understanding_rate?: number;
  contact_consent?: boolean;
  experience_rate?: number;
  favorite_chat_feature_aspect?: string;
  responsiveness_rate?: number;
  suggested_chat_improvements?: string;
}

export interface HandlersUpdateFunnelUserRequest {
  country?: string;
  url?: string;
}

export interface HandlersUpdateToolFeedbackRequest {
  contact_consent?: boolean;
  excited_about?: string;
  experience_rate?: number;
  responsiveness_rate?: number;
  suggested_improvements?: string;
  tool_understanding_rate?: number;
}

export interface HandlersUpdateTooleExecutionResultRequest {
  ai_response?: string;
}

export interface HandlersUpdateUserOnboardingV2Request {
  ai_experience?: string;
  company_size?: string;
  language?: string;
  position?: string;
  profession?: string;
  purposes?: string;
}

export interface HandlersUploadAvatarResponse {
  avatar_url?: string;
}

export interface HandlersUserMessageReactionRequest {
  reaction?: number;
}

export interface HandlersZeroGPTAIDetectReactionRequest {
  reaction?: number;
}

export interface HandlersZeroGPTAIDetectorRequest {
  input?: string;
}

export type ModelsABTestsPresenter = Record<string, string>;

export interface ModelsAIHumanizerInputs {
  mode?: BypassgptModelType;
  original_text?: string;
  purpose?: UndetectablePurpose;
  readability?: UndetectableReadability;
  strength?: UndetectableStrength;
}

export interface ModelsAIHumanizerProcessingComplete {
  extra_words_left?: number;
  extra_words_total?: number;
  humanized_text?: string;
  id?: string;
  original_text?: string;
  purpose?: UndetectablePurpose;
  readability?: UndetectableReadability;
  regular_words_left?: number;
  regular_words_total?: number;
}

export interface ModelsAIHumanizerRequest {
  content?: string;
  cost?: number;
  created?: number;
  created_at?: string;
  deletedAt?: BunNullTime;
  humanized_content?: string;
  id?: string;
  is_user_copied_response?: boolean;
  purpose?: UndetectablePurpose;
  readability?: UndetectableReadability;
  request_id?: string;
  status?: string;
  strength?: UndetectableStrength;
  updated_at?: string;
  user_id?: string;
  user_reaction?: number;
}

export interface ModelsAIHumanizerResponse {
  /** response count */
  chars_count?: number;
  created_at?: string;
  execution_id?: string;
  is_user_copied_response?: boolean;
  purpose?: UndetectablePurpose;
  readability?: UndetectableReadability;
  request_message?: string;
  response_message?: string;
  updated_at?: string;
  user_reaction?: number;
  /** response count */
  words_count?: number;
}

export interface ModelsAIHumanizerTool {
  extra_words_left?: number;
  extra_words_total?: number;
  id?: string;
  is_limited?: boolean;
  last_inputs?: ModelsAIHumanizerInputs;
  regular_words_left?: number;
  regular_words_total?: number;
  title?: string;
}

export interface ModelsAiDetectorExportComplete {
  ai_detection_score?: number;
  document_link?: string;
  export_id?: string;
  extra_words_left?: number;
  extra_words_total?: number;
  regular_characters_left?: number;
  regular_characters_total?: number;
  request_message?: string;
}

export interface ModelsAiDetectorInputs {
  file_content?: string;
  is_sandbox?: boolean;
}

export interface ModelsAiDetectorListExecutionsItem {
  ai_detection_score?: number;
  document_link?: string;
  export_id?: string;
  reaction?: number;
  request_message?: string;
}

export interface ModelsAiDetectorTool {
  extra_words_left?: number;
  extra_words_total?: number;
  id?: string;
  is_limited?: boolean;
  last_inputs?: ModelsAiDetectorInputs;
  regular_words_left?: number;
  regular_words_total?: number;
  title?: string;
}

export interface ModelsAiEditAction {
  action?: ModelsAiEditActionType;
  name?: string;
}

export enum ModelsAiEditActionType {
  AiEditActionTypeImprove = 'improve',
  AiEditActionTypeRewrite = 'rewrite',
  AiEditActionTypeSummarize = 'summarize',
  AiEditActionTypeShorten = 'shorten',
  AiEditActionTypeMakeFriendly = 'make_friendly',
  AiEditActionTypeMakeFormal = 'make_formal',
}

export interface ModelsArticleOutline {
  items?: ModelsArticleOutlineItems[];
  topic?: string;
  word_count?: number;
}

export interface ModelsArticleOutlineItems {
  content?: string;
  items?: ModelsArticleOutlineItems[];
  order?: string;
}

export interface ModelsArticleResponse {
  ai_model?: string;
  created_at?: string;
  execution_results?: ModelsToolExecutionResponseArticleResponse[];
  id?: string;
  tool_id?: string;
  updated_at?: string;
  user_id?: string;
  user_inputs?: number[];
}

export interface ModelsBIOrder {
  amount?: number;
  created_at?: string;
  created_at_solid?: BunNullTime;
  currency?: string;
  customer_email?: string;
  customer_id?: string;
  descriptor?: string;
  failed_reason?: string;
  fraudulent?: boolean;
  invoice_id?: UuidNullUUID;
  marketing_amount?: number;
  marketing_currency?: string;
  method?: string;
  order_description?: string;
  order_id?: string;
  order_status?: ModelsOrderStatus;
  processed_at?: string;
  processing_amount?: number;
  processing_currency?: string;
  product_id?: string;
  purchase_source?: string;
  refunded_amount?: number;
  retry_attempt?: number;
  subscription_id?: UuidNullUUID;
  traffic_source?: string;
  updated_at?: string;
  updated_at_solid?: BunNullTime;
  verify_url?: string;
}

export interface ModelsChat {
  chat_type?: ModelsChatType;
  created_at?: string;
  current_personality?: ModelsChatPersonality;
  id?: string;
  name?: string;
  updated_at?: string;
  user_id?: string;
}

export enum ModelsChatEvent {
  EventMessageCreated = 'message_created',
  EventChatCreated = 'chat_created',
  EventAIProcessing = 'ai_processing',
  EventAIResponseChunk = 'ai_response_chunk',
  EventConversationComplete = 'conversation_complete',
  EventToolExecutionComplete = 'tool_execution_complete',
  EventToolExecutionCreated = 'tool_execution_created',
  EventToolExecutionChunk = 'tool_execution_chunk',
  EventAGAOutlineReady = 'article_outline_ready',
  EventKeepAlive = 'keepalive',
  ChromeExtensionActionResponseReady = 'chrome_extension_response_ready',
  ChatToolPromoReady = 'chat_tool_promo',
  AiEditResponseChunkReady = 'ai_edit_response_chunk',
  AiEditResponse = 'ai_edit_response_ready',
  AIDetectorResponseReady = 'ai_detector_response_ready',
  EventSimplePromptChunk = 'simple_prompt_chunk',
  EventSimplePropmptComplete = 'simple_prompt_complete',
  EventAIHumanizerProcessingComplete = 'ai_humanizer_processing_complete',
  ImageGenerationComplete = 'image_generation_complete',
}

export interface ModelsChatFeedback {
  chat_id?: string;
  chat_understanding_rate?: number;
  contact_consent?: boolean;
  created_at?: string;
  experience_rate?: number;
  favorite_chat_feature_aspect?: string;
  id?: string;
  message_id?: string;
  responsiveness_rate?: number;
  suggested_chat_improvements?: string;
  updated_at?: string;
  user_id?: string;
}

export interface ModelsChatMessage {
  ai_model?: string;
  ai_response?: string;
  chat_id?: string;
  created_at?: string;
  id?: string;
  is_user_copied_response?: boolean;
  /** IsHidden             bool            `json:"is_hidden" bun:"is_hidden"` */
  message_context?: ModelsMessageContext;
  personality?: ModelsChatPersonality;
  response_time_ms?: number;
  ui_prompt?: string;
  ui_response?: string;
  updated_at?: string;
  user_id?: string;
  user_prompt?: string;
  user_reaction?: number;
}

export enum ModelsChatPersonality {
  ChatPersonaGeneral = 'general',
  ChatPersonaCareerCounselor = 'career_counselor',
  ChatPersonaMarketingDirector = 'marketing_director',
  ChatPersonaMotivationCoach = 'motivational_coach',
  ChatPersonaBusinessAnalyst = 'business_analyst',
  ChatPersonaSalesProfessional = 'sales_professional',
  ChatPersonaCopywritingMaster = 'copywriting_master',
}

export enum ModelsChatType {
  ChatTypeScrappingFile = 'scraping_file',
  ChatTypeScrappingLink = 'scraping_link',
}

export interface ModelsChatUpdateFields {
  chat_type?: ModelsChatType;
  current_personality?: ModelsChatPersonality;
  name?: string;
}

export enum ModelsChromeExtensionActionType {
  ChromeExtensionActionTypeSummarize = 'summarize',
  ChromeExtensionActionTypeImprove = 'improve',
  ChromeExtensionActionTypeSimplify = 'simplify',
  ChromeExtensionActionTypeParaphrase = 'paraphrase',
  ChromeExtensionActionTypeInformalTone = 'informal_tone',
  ChromeExtensionActionTypeFormalTone = 'formal_tone',
  ChromeExtensionActionTypeCheckGrammar = 'check_grammar',
}

export interface ModelsChromeExtenstionResponse {
  ai_response?: string;
}

export interface ModelsFeedbackV2Feature {
  chat_id?: string;
  created_at?: string;
  feature_name?: string;
  id?: string;
  rate?: number;
  response?: string;
  slug?: string;
  tool_id?: string;
  updated_at?: string;
  user_id?: string;
}

export interface ModelsFeedbackV2Output {
  chat_id?: string;
  comment?: string;
  created_at?: string;
  id?: string;
  rate?: number;
  reasons?: string[];
  response?: string;
  slug?: string;
  tool_id?: string;
  updated_at?: string;
  user_id?: string;
}

export interface ModelsFeedbackV2RateBar {
  chat_id?: string;
  created_at?: string;
  id?: string;
  rate?: number;
  response?: string;
  slug?: string;
  tool_id?: string;
  updated_at?: string;
  user_id?: string;
}

export interface ModelsFeedbacksV2FeatureRequest {
  chat_id?: string;
  feature_name?: string;
  id?: string;
  rate?: number;
  response?: string;
  slug?: string;
  tool_id?: string;
}

export interface ModelsFeedbacksV2OutputRequest {
  chat_id?: string;
  comment?: string;
  id?: string;
  rate?: number;
  reasons?: string[];
  response?: string;
  slug?: string;
  tool_id?: string;
}

export interface ModelsFeedbacksV2RateBarRequest {
  chat_id?: string;
  id?: string;
  rate?: number;
  response?: string;
  slug?: string;
  tool_id?: string;
}

export interface ModelsFunnelUserFields {
  country?: string;
  created_at?: string;
  id?: string;
  url?: string;
  user_id?: string;
}

export interface ModelsImageGenerationPresenter {
  created_at?: string;
  hd_quality?: boolean;
  id?: string;
  image_jpeg_src?: string;
  image_png_src?: string;
  image_size?: string;
  instruction?: string;
  preview_base64_image?: string;
  reaction?: number;
  style?: string;
  updated_at?: string;
}

export interface ModelsLastAAGResults {
  content?: ModelsToolExecutionArticleResponse;
  outline?: ModelsArticleOutline;
  topic?: string;
}

export enum ModelsMessageContext {
  GoogleMessageContext = 'google',
  SystemMessageContext = 'system',
  DefaultMessageContext = 'default',
}

export interface ModelsMessageOnInteractiveSearch {
  ai_response?: string;
  user_prompt?: string;
}

export enum ModelsMessageType {
  MessageTypeScrappingFile = 'scraping_file',
  MessageTypeScrappingLink = 'scraping_link',
}

export enum ModelsOrderStatus {
  OrderStatusCreated = 'created',
  OrderStatusProcessing = 'processing',
  OrderStatusDeclined = 'declined',
  OrderStatusSettlePending = 'settle_pending',
  OrderStatus3DsVerify = '3ds_verify',
  OrderStatusApproved = 'approved',
  OrderStatusRefunded = 'refunded',
  OrderStatusAuthFailed = 'auth_failed',
  OrderStatusAuthOk = 'auth_ok',
  OrderStatusVoidOk = 'void_ok',
  OrderStatusPartialSettled = 'partial_settled',
  OrderStatusSettleOk = 'settle_ok',
}

export enum ModelsPauseUnit {
  PauseUnitDay = 'day',
  PauseUnitWeek = 'week',
  PauseUnitMonth = 'month',
}

export interface ModelsPlagiarismCheckInputs {
  file_content?: string;
  is_sandbox?: boolean;
}

export interface ModelsPlagiarismCheckerExportComplete {
  aggregated_score?: number;
  document_link?: string;
  export_id?: string;
  extra_words_left?: number;
  extra_words_total?: number;
  identical_words?: number;
  minor_changed_words?: number;
  regular_words_left?: number;
  regular_words_total?: number;
  related_meaning_words?: number;
  request_message?: string;
  sources_count?: number;
}

export interface ModelsPlagiarismCheckerListExecutionsItem {
  aggregated_score?: number;
  document_link?: string;
  export_id?: string;
  identical_words?: number;
  minor_changed_words?: number;
  reaction?: number;
  related_meaning_words?: number;
  request_message?: string;
  sources_count?: number;
}

export interface ModelsPlagiarismCheckerTool {
  extra_words_left?: number;
  extra_words_total?: number;
  id?: string;
  is_limited?: boolean;
  last_inputs?: ModelsPlagiarismCheckInputs;
  regular_words_left?: number;
  regular_words_total?: number;
  title?: string;
}

export interface ModelsPresenterTool {
  items?: ModelsToolListItem[];
  name?: string;
}

export interface ModelsProduct {
  alternative_non_trial_name?: string;
  bi_is_upsell?: boolean;
  billing_period?: number;
  created_at?: string;
  currency?: string;
  description?: string;
  description_ui?: string;
  details_ui?: string;
  discount_percentage_ui?: number;
  has_trial_period?: boolean;
  id?: string;
  is_popular_ui?: boolean;
  is_solid?: boolean;
  is_unlimited?: boolean;
  name?: string;
  new_price_ui?: number;
  old_price_ui?: number;
  price?: number;
  regularity?: ModelsRegularity;
  regularity_ui?: ModelsRegularity;
  solid_name_for_bi?: string;
  trial_amount?: number;
  trial_period?: number;
  trial_words_amount?: number;
  updated_at?: string;
  words_amount?: number;
}

export interface ModelsProfessionalArea {
  description?: string;
  icon?: string;
  id?: string;
  title?: string;
}

export interface ModelsProfileUsageStatistics {
  user_last_tool_name?: string;
  user_popular_tool_name?: string;
}

export enum ModelsRegularity {
  RegularityDaily = 'day',
  RegularityMonthly = 'month',
  RegularityYearly = 'year',
  RegularityWeekly = 'week',
  RegularityOnetime = 'onetime',
}

export enum ModelsSubscriptionStatus {
  SubscriptionStatusActive = 'active',
  SubscriptionStatusCancelled = 'cancelled',
  SubscriptionStatusPaused = 'paused',
  SubscriptionStatusPausePending = 'pause_pending',
  SubscriptionStatusRedemption = 'redemption',
  SubscriptionStatusCancelPending = 'cancel_pending',
}

export interface ModelsToolPresenter {
  ai_edit_actions?: ModelsAiEditAction[];
  allow_manual_ai_edit?: boolean;
  category_id?: string;
  category_title?: string;
  created_at?: string;
  description?: string;
  emoji?: string;
  id?: string;
  image_src?: string;
  is_limited?: boolean;
  json_schema?: number[];
  last_inputs?: ModelsToolUserInput;
  model?: string;
  name?: string;
  path?: string;
  slug?: string;
  tags?: string[];
  ui_schema?: number[];
  unique_users?: number;
  updated_at?: string;
}

export interface ModelsToolBoardFeedback {
  contact_consent?: boolean;
  created_at?: string;
  id?: string;
  updated_at?: string;
  user_id?: string;
  wanted_tool_description?: string;
  wanted_tool_name?: string;
}

export interface ModelsToolCategory {
  created_at?: string;
  description?: string;
  id?: string;
  name?: string;
  order_index?: number;
  updated_at?: string;
}

export interface ModelsToolExecution {
  ai_model?: string;
  created_at?: string;
  execution_results?: ModelsToolExecutionResponse[];
  id?: string;
  tool_id?: string;
  updated_at?: string;
  user_id?: string;
  user_inputs?: number[];
}

export interface ModelsToolExecutionArticleResponse {
  ai_model?: string;
  created_at?: string;
  execution_results?: ModelsToolExecutionResponseArticleResponse[];
  id?: string;
  tool_id?: string;
  updated_at?: string;
  user_id?: string;
  user_inputs?: number[];
}

export interface ModelsToolExecutionResponse {
  ai_edit_items?: ModelsToolExecutionResponseAiEdit[];
  ai_response?: string;
  chars_count?: number;
  children?: ModelsToolExecutionResponse[];
  created_at?: string;
  execution_id?: string;
  id?: string;
  is_user_copied_response?: boolean;
  response_time_ms?: number;
  updated_at?: string;
  user_id?: string;
  user_reaction?: number;
  words_count?: number;
}

export interface ModelsToolExecutionResponseArticleResponse {
  ai_edit_items?: ModelsToolExecutionResponseAiEdit[];
  ai_response?: string;
  chars_count?: number;
  children?: ModelsToolExecutionResponse[];
  created_at?: string;
  execution_id?: string;
  id?: string;
  is_user_copied_response?: boolean;
  response_time_ms?: number;
  title?: string;
  updated_at?: string;
  user_id?: string;
  user_reaction?: number;
  words_count?: number;
}

export interface ModelsToolExecutionResponseAiEdit {
  action_type?: ModelsAiEditActionType;
  ai_edit_result?: string;
  ai_model?: string;
  created_at?: string;
  id?: string;
  is_user_copied_response?: boolean;
  prompt?: string;
  updated_at?: string;
  user_reaction?: number;
}

export interface ModelsToolFeedback {
  contact_consent?: boolean;
  created_at?: string;
  excited_about?: string;
  experience_rate?: number;
  id?: string;
  responsiveness_rate?: number;
  suggested_improvements?: string;
  tool_id?: string;
  tool_understanding_rate?: number;
  updated_at?: string;
  user_id?: string;
}

export interface ModelsToolListItem {
  ai_edit_actions?: ModelsAiEditAction[];
  allow_manual_ai_edit?: boolean;
  category_id?: string;
  category_title?: string;
  created_at?: string;
  description?: string;
  emoji?: string;
  id?: string;
  image_src?: string;
  json_schema?: ModelsOmit;
  model?: string;
  name?: string;
  path?: string;
  rating_value?: number;
  slug?: string;
  tags?: string[];
  template?: ModelsOmit;
  ui_schema?: ModelsOmit;
  unique_users?: number;
  updated_at?: string;
}

export type ModelsToolUserInput = Record<string, any>;

export interface ModelsToolWithCategory {
  ai_edit_actions?: ModelsAiEditAction[];
  allow_manual_ai_edit?: boolean;
  category?: ModelsToolCategory;
  category_id?: string;
  category_title?: string;
  created_at?: string;
  description?: string;
  emoji?: string;
  id?: string;
  image_src?: string;
  is_limited?: boolean;
  json_schema?: number[];
  model?: string;
  name?: string;
  path?: string;
  slug?: string;
  tags?: string[];
  ui_schema?: number[];
  unique_users?: number;
  updated_at?: string;
}

export interface ModelsTotal {
  current_page?: number;
  page_size?: number;
  total_count?: number;
  total_pages?: number;
}

export interface ModelsUser {
  ab_tests?: ModelsABTestsPresenter;
  avatar_url?: string;
  created_at?: string;
  email?: string;
  extra_fields?: number[];
  gclid?: string;
  id?: string;
  is_chrome_extension_installed?: boolean;
  is_onboarded?: boolean;
  is_test_user?: boolean;
  name?: string;
  product_id?: string;
  product_name?: string;
  response_count_total?: number;
  status?: ModelsUserStatus;
  subscription_status?: ModelsSubscriptionStatus;
  updated_at?: string;
  user_features?: {
    detectors_enabled?: boolean;
    image_generation_enabled?: boolean;
  };
  user_onboarding?: ModelsUserOnboardingV2;
  user_product?: ModelsProduct;
  user_subscription?: ModelsUserSubscriptionPresenter;
  was_in_redemption?: boolean;
  words_amount_left?: number;
}

export interface ModelsUserOnboardingV2 {
  ai_experience?: string;
  company_size?: string;
  created_at?: string;
  is_finished?: boolean;
  language?: string;
  position?: string;
  profession?: string;
  purposes?: string;
  updated_at?: string;
  user_id?: string;
}

export enum ModelsUserStatus {
  CreatedStatus = 'created',
  InvitedStatus = 'invited',
  TrialStatus = 'trial_subscription',
  ActiveStatus = 'active_subscription',
  InactiveStatus = 'inactive_subscription',
}

export interface ModelsUserSubscriptionPresenter {
  billing_period?: string;
  cancel_code?: string;
  cancel_for_change_subs?: boolean;
  cancel_for_change_subs_to_product_id?: string;
  cancel_message?: string;
  cancel_request_at?: BunNullTime;
  cancelled_at?: BunNullTime;
  created_at?: string;
  expired_at?: BunNullTime;
  id?: string;
  is_current?: boolean;
  is_trial?: boolean;
  is_unlimited?: boolean;
  next_charge_at?: BunNullTime;
  pause_from?: BunNullTime;
  pause_to?: BunNullTime;
  payment_type?: string;
  product_id?: string;
  response_count_total?: number;
  saved_time_minutes?: number;
  solid_event_id?: string;
  started_at?: BunNullTime;
  status?: ModelsSubscriptionStatus;
  subscription_name?: string;
  subscription_price?: number;
  updated_at?: string;
  user_id?: string;
  words_amount_left?: number;
  words_amount_total?: number;
  words_generated_total?: number;
}

export interface ModelsUserSubscriptionSwagger {
  billing_period?: string;
  cancel_code?: string;
  cancel_for_change_subs?: boolean;
  cancel_for_change_subs_to_product_id?: string;
  cancel_message?: string;
  cancel_request_at?: BunNullTime;
  cancelled_at?: string;
  created_at?: string;
  expired_at?: string;
  id?: string;
  is_current?: boolean;
  is_trial?: boolean;
  is_unlimited?: boolean;
  next_charge_at?: string;
  pause_from?: string;
  pause_to?: string;
  payment_type?: string;
  product_id?: string;
  response_count_total?: number;
  saved_time_minutes?: number;
  solid_event_id?: string;
  started_at?: string;
  status?: ModelsSubscriptionStatus;
  subscription_name?: string;
  subscription_price?: number;
  updated_at?: string;
  user_id?: string;
  words_amount_left?: number;
  words_amount_total?: number;
  words_generated_total?: number;
}

export interface ModelsUserUpdateFields {
  extra_fields?: number[];
  is_onboarded?: boolean;
  name?: string;
  was_in_redemption?: boolean;
}

export interface ModelsZeroGPTAIDetect {
  additional_feedback?: string;
  ai_words?: number;
  code?: number;
  created_at?: BunNullTime;
  entity_id?: number;
  fake_percentage?: number;
  feedback?: string;
  id?: string;
  input_text?: string;
  is_human?: number;
  is_success?: boolean;
  is_user_copied_response?: boolean;
  message?: string;
  text_words?: number;
  updated_at?: BunNullTime;
  user_id?: string;
  user_reaction?: number;
}

export interface ModelsZeroGPTAIDetectPresenter {
  additional_feedback?: string;
  ai_words?: number;
  code?: number;
  created_at?: BunNullTime;
  entity_id?: number;
  extra_words_left?: number;
  extra_words_total?: number;
  fake_percentage?: number;
  feedback?: string;
  id?: string;
  input_text?: string;
  is_human?: number;
  is_success?: boolean;
  is_user_copied_response?: boolean;
  message?: string;
  regular_words_left?: number;
  regular_words_total?: number;
  text_words?: number;
  updated_at?: BunNullTime;
  user_id?: string;
  user_reaction?: number;
}

export type ModelsOmit = object;

export interface RenderHTTPResponse {
  data?: any;
  meta?: any;
}

export enum UndetectablePurpose {
  GeneralWriting = 'General Writing',
  Essay = 'Essay',
  Article = 'Article',
  MarketingMaterial = 'Marketing Material',
  Story = 'Story',
  CoverLetter = 'Cover Letter',
  Report = 'Report',
  BusinessMaterial = 'Business Material',
  LegalMaterial = 'Legal Material',
}

export enum UndetectableReadability {
  HighSchool = 'High School',
  University = 'University',
  Doctorate = 'Doctorate',
  Journalist = 'Journalist',
  Marketing = 'Marketing',
}

export enum UndetectableStrength {
  Quality = 'Quality',
  Balanced = 'Balanced',
  MoreHuman = 'More Human',
}

export interface UuidNullUUID {
  uuid?: string;
  /** Valid is true if UUID is not NULL */
  valid?: boolean;
}

export interface ZerogptDetectTextResponse {
  code?: number;
  data?: ZerogptDetectTextResponseData;
  message?: string;
  success?: boolean;
}

export interface ZerogptDetectTextResponseData {
  additional_feedback?: string;
  aiWords?: number;
  fakePercentage?: number;
  feedback?: string;
  h?: string[];
  id?: number;
  input_text?: string;
  isHuman?: number;
  textWords?: number;
}

export type QueryParamsType = Record<string | number, any>;
export type ResponseFormat = keyof Omit<Body, 'body' | 'bodyUsed'>;

export interface FullRequestParams extends Omit<RequestInit, 'body'> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseFormat;
  /** request body */
  body?: unknown;
  /** base url */
  baseUrl?: string;
  /** request cancellation token */
  cancelToken?: CancelToken;
}

export type RequestParams = Omit<
  FullRequestParams,
  'body' | 'method' | 'query' | 'path'
>;

export interface ApiConfig<SecurityDataType = unknown> {
  baseUrl?: string;
  baseApiParams?: Omit<RequestParams, 'baseUrl' | 'cancelToken' | 'signal'>;
  securityWorker?: (
    securityData: SecurityDataType | null,
  ) => Promise<RequestParams | void> | RequestParams | void;
  customFetch?: typeof fetch;
}

export interface HttpResponse<D extends unknown, E extends unknown = unknown>
  extends Response {
  data: D;
  error: E;
}

type CancelToken = Symbol | string | number;

export enum ContentType {
  Json = 'application/json',
  FormData = 'multipart/form-data',
  UrlEncoded = 'application/x-www-form-urlencoded',
  Text = 'text/plain',
}

export class HttpClient<SecurityDataType = unknown> {
  public baseUrl: string = 'https://{host_api}';
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>['securityWorker'];
  private abortControllers = new Map<CancelToken, AbortController>();
  private customFetch = (...fetchParams: Parameters<typeof fetch>) =>
    fetch(...fetchParams);

  private baseApiParams: RequestParams = {
    credentials: 'same-origin',
    headers: {},
    redirect: 'follow',
    referrerPolicy: 'no-referrer',
  };

  constructor(apiConfig: ApiConfig<SecurityDataType> = {}) {
    Object.assign(this, apiConfig);
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  protected encodeQueryParam(key: string, value: any) {
    const encodedKey = encodeURIComponent(key);
    return `${encodedKey}=${encodeURIComponent(
      typeof value === 'number' ? value : `${value}`,
    )}`;
  }

  protected addQueryParam(query: QueryParamsType, key: string) {
    return this.encodeQueryParam(key, query[key]);
  }

  protected addArrayQueryParam(query: QueryParamsType, key: string) {
    const value = query[key];
    return value.map((v: any) => this.encodeQueryParam(key, v)).join('&');
  }

  protected toQueryString(rawQuery?: QueryParamsType): string {
    const query = rawQuery || {};
    const keys = Object.keys(query).filter(
      (key) => 'undefined' !== typeof query[key],
    );
    return keys
      .map((key) =>
        Array.isArray(query[key])
          ? this.addArrayQueryParam(query, key)
          : this.addQueryParam(query, key),
      )
      .join('&');
  }

  protected addQueryParams(rawQuery?: QueryParamsType): string {
    const queryString = this.toQueryString(rawQuery);
    return queryString ? `?${queryString}` : '';
  }

  private contentFormatters: Record<ContentType, (input: any) => any> = {
    [ContentType.Json]: (input: any) =>
      input !== null && (typeof input === 'object' || typeof input === 'string')
        ? JSON.stringify(input)
        : input,
    [ContentType.Text]: (input: any) =>
      input !== null && typeof input !== 'string'
        ? JSON.stringify(input)
        : input,
    [ContentType.FormData]: (input: any) =>
      Object.keys(input || {}).reduce((formData, key) => {
        const property = input[key];
        formData.append(
          key,
          property instanceof Blob
            ? property
            : typeof property === 'object' && property !== null
            ? JSON.stringify(property)
            : `${property}`,
        );
        return formData;
      }, new FormData()),
    [ContentType.UrlEncoded]: (input: any) => this.toQueryString(input),
  };

  protected mergeRequestParams(
    params1: RequestParams,
    params2?: RequestParams,
  ): RequestParams {
    return {
      ...this.baseApiParams,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...(this.baseApiParams.headers || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    };
  }

  protected createAbortSignal = (
    cancelToken: CancelToken,
  ): AbortSignal | undefined => {
    if (this.abortControllers.has(cancelToken)) {
      const abortController = this.abortControllers.get(cancelToken);
      if (abortController) {
        return abortController.signal;
      }
      return void 0;
    }

    const abortController = new AbortController();
    this.abortControllers.set(cancelToken, abortController);
    return abortController.signal;
  };

  public abortRequest = (cancelToken: CancelToken) => {
    const abortController = this.abortControllers.get(cancelToken);

    if (abortController) {
      abortController.abort();
      this.abortControllers.delete(cancelToken);
    }
  };

  public request = async <T = any, E = any>({
    body,
    secure,
    path,
    type,
    query,
    format,
    baseUrl,
    cancelToken,
    ...params
  }: FullRequestParams): Promise<HttpResponse<T, E>> => {
    const secureParams =
      ((typeof secure === 'boolean' ? secure : this.baseApiParams.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const queryString = query && this.toQueryString(query);
    const payloadFormatter = this.contentFormatters[type || ContentType.Json];
    const responseFormat = format || requestParams.format;

    return this.customFetch(
      `${baseUrl || this.baseUrl || ''}${path}${
        queryString ? `?${queryString}` : ''
      }`,
      {
        ...requestParams,
        headers: {
          ...(requestParams.headers || {}),
          ...(type && type !== ContentType.FormData
            ? { 'Content-Type': type }
            : {}),
        },
        signal: cancelToken
          ? this.createAbortSignal(cancelToken)
          : requestParams.signal,
        body:
          typeof body === 'undefined' || body === null
            ? null
            : payloadFormatter(body),
      },
    ).then(async (response) => {
      const r = response as HttpResponse<T, E>;
      r.data = null as unknown as T;
      r.error = null as unknown as E;

      const data = !responseFormat
        ? r
        : await response[responseFormat]()
            .then((data) => {
              if (r.ok) {
                r.data = data;
              } else {
                r.error = data;
              }
              return r;
            })
            .catch((e) => {
              r.error = e;
              return r;
            });

      if (cancelToken) {
        this.abortControllers.delete(cancelToken);
      }

      if (!response.ok) throw data;
      return data;
    });
  };
}

/**
 * @title JustDone AI API
 * @version 1.0
 * @baseUrl https://{host_api}
 * @contact
 *
 * This is a JustDone AI API.
 */
export class Api<
  SecurityDataType extends unknown,
> extends HttpClient<SecurityDataType> {
  api = {
    /**
     * @description Get AI Humanizer
     *
     * @tags ai_humanizer
     * @name V1AiHumanizerList
     * @summary Get AI Humanizer
     * @request GET:/api/v1/ai-humanizer
     */
    v1AiHumanizerList: (params: RequestParams = {}) =>
      this.request<
        RenderHTTPResponse & {
          data?: ModelsAIHumanizerTool;
        },
        any
      >({
        path: `/api/v1/ai-humanizer`,
        method: 'GET',
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags ai_humanizer
     * @name V1AiHumanizerCreate
     * @summary Submit document to AI Humanizer
     * @request POST:/api/v1/ai-humanizer
     */
    v1AiHumanizerCreate: (
      body: HandlersAIHumanizerRequest,
      params: RequestParams = {},
    ) =>
      this.request<
        RenderHTTPResponse & {
          data?: ModelsAIHumanizerProcessingComplete;
        },
        any
      >({
        path: `/api/v1/ai-humanizer`,
        method: 'POST',
        body: body,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Delete AI Humanizer executions
     *
     * @tags ai_humanizer
     * @name V1AiHumanizerExecutionsDelete
     * @summary Delete AI Humanizer executions
     * @request DELETE:/api/v1/ai-humanizer/executions
     */
    v1AiHumanizerExecutionsDelete: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/v1/ai-humanizer/executions`,
        method: 'DELETE',
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Get AI Humanizer executions
     *
     * @tags ai_humanizer
     * @name V1AiHumanizerExecutionsList
     * @summary Get AI Humanizer executions
     * @request GET:/api/v1/ai-humanizer/executions
     */
    v1AiHumanizerExecutionsList: (
      query?: {
        /** Page */
        page?: number;
        /** Per page */
        per_page?: number;
        /** Sort by */
        sort_by?: string;
        /** Sort type */
        sort_type?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        RenderHTTPResponse & {
          data?: ModelsAIHumanizerRequest[];
          meta?: ModelsTotal;
        },
        any
      >({
        path: `/api/v1/ai-humanizer/executions`,
        method: 'GET',
        query: query,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Copy AI Humanizer execution result
     *
     * @tags ai_humanizer
     * @name V1AiHumanizerExecutionsCopyCreate
     * @summary Copy AI Humanizer execution result
     * @request POST:/api/v1/ai-humanizer/executions/{execution_id}/copy
     */
    v1AiHumanizerExecutionsCopyCreate: (
      executionId: string,
      params: RequestParams = {},
    ) =>
      this.request<
        RenderHTTPResponse & {
          data?: ModelsAIHumanizerResponse;
        },
        any
      >({
        path: `/api/v1/ai-humanizer/executions/${executionId}/copy`,
        method: 'POST',
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Set AI Humanizer execution reaction
     *
     * @tags ai_humanizer
     * @name V1AiHumanizerExecutionsReactionCreate
     * @summary Set AI Humanizer execution reaction
     * @request POST:/api/v1/ai-humanizer/executions/{execution_id}/reaction
     */
    v1AiHumanizerExecutionsReactionCreate: (
      executionId: string,
      body: HandlersAIHumanizerExecutionReactionRequest,
      params: RequestParams = {},
    ) =>
      this.request<
        RenderHTTPResponse & {
          data?: object;
        },
        any
      >({
        path: `/api/v1/ai-humanizer/executions/${executionId}/reaction`,
        method: 'POST',
        body: body,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description User Login
     *
     * @tags auth
     * @name V1AuthLoginCreate
     * @summary Login
     * @request POST:/api/v1/auth/login
     */
    v1AuthLoginCreate: (
      body: HandlersLoginRequest,
      params: RequestParams = {},
    ) =>
      this.request<
        RenderHTTPResponse & {
          data?: HandlersLoginResponse;
        },
        any
      >({
        path: `/api/v1/auth/login`,
        method: 'POST',
        body: body,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Logout
     *
     * @tags auth
     * @name V1AuthLogoutCreate
     * @summary Logout
     * @request POST:/api/v1/auth/logout
     */
    v1AuthLogoutCreate: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/v1/auth/logout`,
        method: 'POST',
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags OnboardingV2
     * @name V1AuthOnboardingV2List
     * @summary Get user onboarding V2
     * @request GET:/api/v1/auth/onboarding/v2
     */
    v1AuthOnboardingV2List: (params: RequestParams = {}) =>
      this.request<
        RenderHTTPResponse & {
          data?: ModelsUserOnboardingV2;
        },
        any
      >({
        path: `/api/v1/auth/onboarding/v2`,
        method: 'GET',
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags OnboardingV2
     * @name V1AuthOnboardingV2Create
     * @summary Update user onboarding V2
     * @request POST:/api/v1/auth/onboarding/v2
     */
    v1AuthOnboardingV2Create: (
      body: HandlersUpdateUserOnboardingV2Request,
      params: RequestParams = {},
    ) =>
      this.request<
        RenderHTTPResponse & {
          data?: ModelsUserOnboardingV2;
        },
        any
      >({
        path: `/api/v1/auth/onboarding/v2`,
        method: 'POST',
        body: body,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Refresh token
     *
     * @tags auth
     * @name V1AuthRefreshTokenCreate
     * @summary Refresh token
     * @request POST:/api/v1/auth/refresh-token
     */
    v1AuthRefreshTokenCreate: (
      body: HandlersRefreshTokenRequest,
      params: RequestParams = {},
    ) =>
      this.request<
        RenderHTTPResponse & {
          data?: HandlersRefreshTokenResponse;
        },
        any
      >({
        path: `/api/v1/auth/refresh-token`,
        method: 'POST',
        body: body,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Sign up
     *
     * @tags auth
     * @name V1AuthSignUpCreate
     * @summary Sign up
     * @request POST:/api/v1/auth/sign-up
     */
    v1AuthSignUpCreate: (
      body: HandlersSignUpRequest,
      params: RequestParams = {},
    ) =>
      this.request<
        RenderHTTPResponse & {
          data?: HandlersSignUpResponse;
        },
        any
      >({
        path: `/api/v1/auth/sign-up`,
        method: 'POST',
        body: body,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Get chats
     *
     * @tags chats
     * @name V1ChatsList
     * @summary Get chats
     * @request GET:/api/v1/chats
     */
    v1ChatsList: (
      query?: {
        /** Page */
        page?: number;
        /** Per page */
        per_page?: number;
        /** Sort by */
        sort_by?: string;
        /** Sort type */
        sort_type?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        RenderHTTPResponse & {
          data?: ModelsChat[];
          meta?: ModelsTotal;
        },
        any
      >({
        path: `/api/v1/chats`,
        method: 'GET',
        query: query,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Create chat
     *
     * @tags chats
     * @name V1ChatsCreate
     * @summary Create chat
     * @request POST:/api/v1/chats
     */
    v1ChatsCreate: (
      body: HandlersCreateChatRequest,
      params: RequestParams = {},
    ) =>
      this.request<
        RenderHTTPResponse & {
          data?: ModelsChat;
        },
        any
      >({
        path: `/api/v1/chats`,
        method: 'POST',
        body: body,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Delete chat
     *
     * @tags chats
     * @name V1ChatsDelete
     * @summary Delete chat
     * @request DELETE:/api/v1/chats/{chat_id}
     */
    v1ChatsDelete: (chatId: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/v1/chats/${chatId}`,
        method: 'DELETE',
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Update chat
     *
     * @tags chats
     * @name V1ChatsUpdate
     * @summary Update chat
     * @request PUT:/api/v1/chats/{chat_id}
     */
    v1ChatsUpdate: (
      chatId: string,
      body: ModelsChatUpdateFields,
      params: RequestParams = {},
    ) =>
      this.request<
        RenderHTTPResponse & {
          data?: ModelsChat;
        },
        any
      >({
        path: `/api/v1/chats/${chatId}`,
        method: 'PUT',
        body: body,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Get chat messages
     *
     * @tags chats
     * @name V1ChatsMessagesDetail
     * @summary Get chat messages
     * @request GET:/api/v1/chats/{chat_id}/messages
     */
    v1ChatsMessagesDetail: (
      chatId: string,
      query?: {
        /** Page */
        page?: number;
        /** Per page */
        per_page?: number;
        /** Sort by */
        sort_by?: string;
        /** Sort type */
        sort_type?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        RenderHTTPResponse & {
          data?: ModelsChatMessage[];
          meta?: ModelsTotal;
        },
        any
      >({
        path: `/api/v1/chats/${chatId}/messages`,
        method: 'GET',
        query: query,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Mark chat message as copied
     *
     * @tags chats
     * @name V1ChatsMessagesMarkCopiedCreate
     * @summary Mark chat message as copied
     * @request POST:/api/v1/chats/{chat_id}/messages/{message_id}/mark_copied
     */
    v1ChatsMessagesMarkCopiedCreate: (
      chatId: string,
      messageId: string,
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/api/v1/chats/${chatId}/messages/${messageId}/mark_copied`,
        method: 'POST',
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Set user message reaction
     *
     * @tags chats
     * @name V1ChatsMessagesReactionCreate
     * @summary Set user message reaction
     * @request POST:/api/v1/chats/{chat_id}/messages/{message_id}/reaction
     */
    v1ChatsMessagesReactionCreate: (
      chatId: string,
      messageId: string,
      body: HandlersUserMessageReactionRequest,
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/api/v1/chats/${chatId}/messages/${messageId}/reaction`,
        method: 'POST',
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Handler chat events
     *
     * @tags chats
     * @name V1ChatsEventsCreate
     * @summary Handler chat events
     * @request POST:/api/v1/chats/events
     */
    v1ChatsEventsCreate: (
      body: HandlersChatEventRequest,
      params: RequestParams = {},
    ) =>
      this.request<HandlersChatEventResponse, any>({
        path: `/api/v1/chats/events`,
        method: 'POST',
        body: body,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags feedback
     * @name V1ChatsFeedbacksCreate
     * @summary Create chat feedback
     * @request POST:/api/v1/chats/feedbacks
     */
    v1ChatsFeedbacksCreate: (
      body: HandlersCreateChatFeedbackRequest,
      params: RequestParams = {},
    ) =>
      this.request<
        RenderHTTPResponse & {
          data?: ModelsChatFeedback;
        },
        any
      >({
        path: `/api/v1/chats/feedbacks`,
        method: 'POST',
        body: body,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags feedback
     * @name V1ChatsFeedbacksUpdate
     * @summary Update chat feedback
     * @request PUT:/api/v1/chats/feedbacks/{feedback_id}
     */
    v1ChatsFeedbacksUpdate: (
      feedbackId: string,
      body: HandlersUpdateChatFeedbackRequest,
      params: RequestParams = {},
    ) =>
      this.request<
        RenderHTTPResponse & {
          data?: ModelsChatFeedback;
        },
        any
      >({
        path: `/api/v1/chats/feedbacks/${feedbackId}`,
        method: 'PUT',
        body: body,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Chrome Extenstion
     *
     * @tags chrome_extension
     * @name V1ChromeExtensionCreate
     * @summary Chrome Extenstion
     * @request POST:/api/v1/chrome_extension
     */
    v1ChromeExtensionCreate: (
      body: HandlersChromeExtensionRequest,
      params: RequestParams = {},
    ) =>
      this.request<
        RenderHTTPResponse & {
          data?: ModelsChromeExtenstionResponse;
        },
        any
      >({
        path: `/api/v1/chrome_extension`,
        method: 'POST',
        body: body,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Chrome Extenstion Stream
     *
     * @tags chrome_extension
     * @name V1ChromeExtensionStreamCreate
     * @summary Chrome Extenstion Stream
     * @request POST:/api/v1/chrome_extension/stream
     */
    v1ChromeExtensionStreamCreate: (
      body: HandlersChromeExtensionRequest,
      params: RequestParams = {},
    ) =>
      this.request<
        RenderHTTPResponse & {
          data?: ModelsChromeExtenstionResponse;
        },
        any
      >({
        path: `/api/v1/chrome_extension/stream`,
        method: 'POST',
        body: body,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Get Copyleaks AIDetector
     *
     * @tags copyleaks
     * @name V1CopyleaksAiDetectorList
     * @summary Get Copyleaks AIDetector
     * @request GET:/api/v1/copyleaks/ai-detector
     */
    v1CopyleaksAiDetectorList: (params: RequestParams = {}) =>
      this.request<
        RenderHTTPResponse & {
          data?: ModelsAiDetectorTool;
        },
        any
      >({
        path: `/api/v1/copyleaks/ai-detector`,
        method: 'GET',
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Copyleaks AIDetector
     *
     * @tags copyleaks
     * @name V1CopyleaksAiDetectorCreate
     * @summary Copyleaks AIDetector
     * @request POST:/api/v1/copyleaks/ai-detector
     */
    v1CopyleaksAiDetectorCreate: (
      body: HandlersCopyleaksAIDetectorRequest,
      params: RequestParams = {},
    ) =>
      this.request<
        RenderHTTPResponse & {
          data?: ModelsAiDetectorExportComplete;
        },
        any
      >({
        path: `/api/v1/copyleaks/ai-detector`,
        method: 'POST',
        body: body,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Delete Copyleaks AI Detector Executions
     *
     * @tags copyleaks
     * @name V1CopyleaksAiDetectorExecutionsDelete
     * @summary Delete Copyleaks AI Detector Executions
     * @request DELETE:/api/v1/copyleaks/ai-detector/executions
     */
    v1CopyleaksAiDetectorExecutionsDelete: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/v1/copyleaks/ai-detector/executions`,
        method: 'DELETE',
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Get Copyleaks Plagiarism Checker Executions
     *
     * @tags copyleaks
     * @name V1CopyleaksAiDetectorExecutionsList
     * @summary Get Copyleaks Plagiarism Checker Executions
     * @request GET:/api/v1/copyleaks/ai-detector/executions
     */
    v1CopyleaksAiDetectorExecutionsList: (
      query?: {
        /** Page */
        page?: number;
        /** Per page */
        per_page?: number;
        /** Sort by */
        sort_by?: string;
        /** Sort type */
        sort_type?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        RenderHTTPResponse & {
          data?: ModelsAiDetectorListExecutionsItem[];
          meta?: ModelsTotal;
        },
        any
      >({
        path: `/api/v1/copyleaks/ai-detector/executions`,
        method: 'GET',
        query: query,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Copy Copyleaks Execution Result
     *
     * @tags copyleaks
     * @name V1CopyleaksExportsCopyCreate
     * @summary Copy Copyleaks Execution Result
     * @request POST:/api/v1/copyleaks/exports/{export_id}/copy
     */
    v1CopyleaksExportsCopyCreate: (
      exportId: string,
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/api/v1/copyleaks/exports/${exportId}/copy`,
        method: 'POST',
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Get PDF report by export ID
     *
     * @tags copyleaks
     * @name V1CopyleaksExportsPdfDetail
     * @summary Get PDF report by export ID
     * @request GET:/api/v1/copyleaks/exports/{export_id}/pdf
     */
    v1CopyleaksExportsPdfDetail: (
      exportId: string,
      params: RequestParams = {},
    ) =>
      this.request<File, any>({
        path: `/api/v1/copyleaks/exports/${exportId}/pdf`,
        method: 'GET',
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Set Copyleaks Execution Reaction
     *
     * @tags copyleaks
     * @name V1CopyleaksExportsReactionCreate
     * @summary Set Copyleaks Execution Reaction
     * @request POST:/api/v1/copyleaks/exports/{export_id}/reaction
     */
    v1CopyleaksExportsReactionCreate: (
      exportId: string,
      body: HandlersCopyleaksExecutionReactionRequest,
      params: RequestParams = {},
    ) =>
      this.request<
        RenderHTTPResponse & {
          data?: object;
        },
        any
      >({
        path: `/api/v1/copyleaks/exports/${exportId}/reaction`,
        method: 'POST',
        body: body,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Get Copyleaks Plagiarism Checker
     *
     * @tags copyleaks
     * @name V1CopyleaksPlagiarismCheckList
     * @summary Get Copyleaks Plagiarism Checker
     * @request GET:/api/v1/copyleaks/plagiarism-check
     */
    v1CopyleaksPlagiarismCheckList: (params: RequestParams = {}) =>
      this.request<
        RenderHTTPResponse & {
          data?: ModelsPlagiarismCheckerTool;
        },
        any
      >({
        path: `/api/v1/copyleaks/plagiarism-check`,
        method: 'GET',
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Copyleaks Plagiarism Check
     *
     * @tags copyleaks
     * @name V1CopyleaksPlagiarismCheckCreate
     * @summary Copyleaks Plagiarism Check
     * @request POST:/api/v1/copyleaks/plagiarism-check
     */
    v1CopyleaksPlagiarismCheckCreate: (
      body: HandlersCopyleaksPlagiarismCheckRequest,
      params: RequestParams = {},
    ) =>
      this.request<
        RenderHTTPResponse & {
          data?: ModelsPlagiarismCheckerExportComplete;
        },
        any
      >({
        path: `/api/v1/copyleaks/plagiarism-check`,
        method: 'POST',
        body: body,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Delete Copyleaks Plagiarism Checker Executions
     *
     * @tags copyleaks
     * @name V1CopyleaksPlagiarismCheckExecutionsDelete
     * @summary Delete Copyleaks Plagiarism Checker Executions
     * @request DELETE:/api/v1/copyleaks/plagiarism-check/executions
     */
    v1CopyleaksPlagiarismCheckExecutionsDelete: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/v1/copyleaks/plagiarism-check/executions`,
        method: 'DELETE',
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Get Copyleaks Plagiarism Checker Executions
     *
     * @tags copyleaks
     * @name V1CopyleaksPlagiarismCheckExecutionsList
     * @summary Get Copyleaks Plagiarism Checker Executions
     * @request GET:/api/v1/copyleaks/plagiarism-check/executions
     */
    v1CopyleaksPlagiarismCheckExecutionsList: (
      query?: {
        /** Page */
        page?: number;
        /** Per page */
        per_page?: number;
        /** Sort by */
        sort_by?: string;
        /** Sort type */
        sort_type?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        RenderHTTPResponse & {
          data?: ModelsPlagiarismCheckerListExecutionsItem[];
          meta?: ModelsTotal;
        },
        any
      >({
        path: `/api/v1/copyleaks/plagiarism-check/executions`,
        method: 'GET',
        query: query,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Create feedback feature
     *
     * @tags feedbacksV2
     * @name V1FeedbacksV2FeaturesCreate
     * @summary Create feedback feature
     * @request POST:/api/v1/feedbacks/v2/features
     */
    v1FeedbacksV2FeaturesCreate: (
      body: ModelsFeedbacksV2FeatureRequest,
      params: RequestParams = {},
    ) =>
      this.request<
        RenderHTTPResponse & {
          data?: ModelsFeedbackV2Feature;
        },
        any
      >({
        path: `/api/v1/feedbacks/v2/features`,
        method: 'POST',
        body: body,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Create feedback output
     *
     * @tags feedbacksV2
     * @name V1FeedbacksV2OutputsCreate
     * @summary Create feedback output
     * @request POST:/api/v1/feedbacks/v2/outputs
     */
    v1FeedbacksV2OutputsCreate: (
      body: ModelsFeedbacksV2OutputRequest,
      params: RequestParams = {},
    ) =>
      this.request<
        RenderHTTPResponse & {
          data?: ModelsFeedbackV2Output;
        },
        any
      >({
        path: `/api/v1/feedbacks/v2/outputs`,
        method: 'POST',
        body: body,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Create feedback rate bar
     *
     * @tags feedbacksV2
     * @name V1FeedbacksV2RateBarsCreate
     * @summary Create feedback rate bar
     * @request POST:/api/v1/feedbacks/v2/rate-bars
     */
    v1FeedbacksV2RateBarsCreate: (
      body: ModelsFeedbacksV2RateBarRequest,
      params: RequestParams = {},
    ) =>
      this.request<
        RenderHTTPResponse & {
          data?: ModelsFeedbackV2RateBar;
        },
        any
      >({
        path: `/api/v1/feedbacks/v2/rate-bars`,
        method: 'POST',
        body: body,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Get funnel user product prices
     *
     * @tags funnels
     * @name V1FunnelsProductsPricesList
     * @summary Get funnel user product prices
     * @request GET:/api/v1/funnels/products/prices
     */
    v1FunnelsProductsPricesList: (
      query: {
        /** Product IDs */
        product_ids: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        RenderHTTPResponse & {
          data?: EntitiesGetPricesResponse;
        },
        any
      >({
        path: `/api/v1/funnels/products/prices`,
        method: 'GET',
        query: query,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Contact support
     *
     * @tags funnels
     * @name V1FunnelsSupportHelpCreate
     * @summary Contact support
     * @request POST:/api/v1/funnels/support/help
     */
    v1FunnelsSupportHelpCreate: (
      body: HandlersFunnelContactSupportRequest,
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/api/v1/funnels/support/help`,
        method: 'POST',
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Create funnel user
     *
     * @tags funnels
     * @name V1FunnelsUsersCreate
     * @summary Create funnel user
     * @request POST:/api/v1/funnels/users
     */
    v1FunnelsUsersCreate: (
      body: HandlersCreateFunnelUserRequest,
      params: RequestParams = {},
    ) =>
      this.request<
        RenderHTTPResponse & {
          data?: ModelsUser;
        },
        any
      >({
        path: `/api/v1/funnels/users`,
        method: 'POST',
        body: body,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Get funnel user
     *
     * @tags funnels
     * @name V1FunnelsUsersDetail
     * @summary Get funnel user
     * @request GET:/api/v1/funnels/users/{user_id}
     */
    v1FunnelsUsersDetail: (userId: string, params: RequestParams = {}) =>
      this.request<
        RenderHTTPResponse & {
          data?: ModelsFunnelUserFields;
        },
        any
      >({
        path: `/api/v1/funnels/users/${userId}`,
        method: 'GET',
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Update funnel user
     *
     * @tags funnels
     * @name V1FunnelsUsersUpdate
     * @summary Update funnel user
     * @request PUT:/api/v1/funnels/users/{user_id}
     */
    v1FunnelsUsersUpdate: (
      userId: string,
      body: HandlersUpdateFunnelUserRequest,
      params: RequestParams = {},
    ) =>
      this.request<
        RenderHTTPResponse & {
          data?: ModelsFunnelUserFields;
        },
        any
      >({
        path: `/api/v1/funnels/users/${userId}`,
        method: 'PUT',
        body: body,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Get funnel user order
     *
     * @tags funnels
     * @name V1FunnelsUsersOrdersDetail
     * @summary Get funnel user order
     * @request GET:/api/v1/funnels/users/{user_id}/orders/{order_id}
     */
    v1FunnelsUsersOrdersDetail: (
      userId: string,
      orderId: string,
      params: RequestParams = {},
    ) =>
      this.request<
        RenderHTTPResponse & {
          data?: ModelsBIOrder;
        },
        any
      >({
        path: `/api/v1/funnels/users/${userId}/orders/${orderId}`,
        method: 'GET',
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Create funnel user payment
     *
     * @tags funnels
     * @name V1FunnelsUsersPaymentsIntentCreate
     * @summary Create funnel user payment
     * @request POST:/api/v1/funnels/users/{user_id}/payments/intent
     */
    v1FunnelsUsersPaymentsIntentCreate: (
      userId: string,
      query: {
        /** Geo country for Apple Pay */
        geo_country?: string;
        /** Currency */
        currency_country?: string;
        /** Currency */
        currency?: string;
        /** Product ID */
        product_id: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        RenderHTTPResponse & {
          data?: EntitiesMerchantData;
        },
        any
      >({
        path: `/api/v1/funnels/users/${userId}/payments/intent`,
        method: 'POST',
        query: query,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Create funnel user payment
     *
     * @tags funnels
     * @name V1FunnelsUsersPaymentsPaypalCreate
     * @summary Create funnel user payment
     * @request POST:/api/v1/funnels/users/{user_id}/payments/paypal
     */
    v1FunnelsUsersPaymentsPaypalCreate: (
      userId: string,
      query: {
        /** Product ID */
        product_id: string;
        /** Currency country */
        currency_country?: string;
        /** Currency */
        currency?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        RenderHTTPResponse & {
          data?: EntitiesInitPaymentPaypalResponse;
        },
        any
      >({
        path: `/api/v1/funnels/users/${userId}/payments/paypal`,
        method: 'POST',
        query: query,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Buy upsell PDF
     *
     * @tags funnels
     * @name V1FunnelsUsersPaymentsUpsellCreate
     * @summary Buy upsell PDF
     * @request POST:/api/v1/funnels/users/{user_id}/payments/upsell
     */
    v1FunnelsUsersPaymentsUpsellCreate: (
      userId: string,
      query: {
        /** Product ID */
        product_id: string;
      },
      body: HandlersFunnelUserPaymentUpsellRequest,
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/api/v1/funnels/users/${userId}/payments/upsell`,
        method: 'POST',
        query: query,
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Send funnel user PDF
     *
     * @tags funnels
     * @name V1FunnelsUsersSendPdfCreate
     * @summary Send funnel user PDF
     * @request POST:/api/v1/funnels/users/{user_id}/send-pdf
     */
    v1FunnelsUsersSendPdfCreate: (userId: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/v1/funnels/users/${userId}/send-pdf`,
        method: 'POST',
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Funnel ZeroGPT Ai Detect
     *
     * @tags zerogpt
     * @name V1FunnelsZerogptCreate
     * @summary Funnel ZeroGPT Ai Detect
     * @request POST:/api/v1/funnels/zerogpt
     */
    v1FunnelsZerogptCreate: (
      body: HandlersZeroGPTAIDetectorRequest,
      params: RequestParams = {},
    ) =>
      this.request<
        RenderHTTPResponse & {
          data?: ZerogptDetectTextResponse;
        },
        any
      >({
        path: `/api/v1/funnels/zerogpt`,
        method: 'POST',
        body: body,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Send offboarding support mail
     *
     * @tags profile
     * @name V1OffboardingSupportCreate
     * @summary Send offboarding support mail
     * @request POST:/api/v1/offboarding/support
     */
    v1OffboardingSupportCreate: (
      body: HandlersOffboardingSupportMailRequest,
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/api/v1/offboarding/support`,
        method: 'POST',
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description ChangePassword
     *
     * @tags Password
     * @name V1PasswordChangeCreate
     * @summary ChangePassword
     * @request POST:/api/v1/password/change
     */
    v1PasswordChangeCreate: (
      body: HandlersChangePasswordRequest,
      params: RequestParams = {},
    ) =>
      this.request<RenderHTTPResponse, any>({
        path: `/api/v1/password/change`,
        method: 'POST',
        body: body,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description ConfirmPassword
     *
     * @tags Password
     * @name V1PasswordConfirmCreate
     * @summary ConfirmPassword
     * @request POST:/api/v1/password/confirm
     */
    v1PasswordConfirmCreate: (
      body: HandlersConfirmPasswordRequest,
      params: RequestParams = {},
    ) =>
      this.request<RenderHTTPResponse, any>({
        path: `/api/v1/password/confirm`,
        method: 'POST',
        body: body,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description ForgotPassword
     *
     * @tags Password
     * @name V1PasswordForgotCreate
     * @summary ForgotPassword
     * @request POST:/api/v1/password/forgot
     */
    v1PasswordForgotCreate: (
      body: HandlersForgotPasswordRequest,
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/api/v1/password/forgot`,
        method: 'POST',
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description ResetPassword
     *
     * @tags Password
     * @name V1PasswordResetCreate
     * @summary ResetPassword
     * @request POST:/api/v1/password/reset
     */
    v1PasswordResetCreate: (
      body: HandlersResetPasswordRequest,
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/api/v1/password/reset`,
        method: 'POST',
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description GetProducts
     *
     * @tags Products
     * @name V1ProductsList
     * @summary GetProducts
     * @request GET:/api/v1/products
     */
    v1ProductsList: (params: RequestParams = {}) =>
      this.request<
        RenderHTTPResponse & {
          data?: ModelsProduct;
        },
        any
      >({
        path: `/api/v1/products`,
        method: 'GET',
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description GetProductByID
     *
     * @tags Products
     * @name V1ProductsDetail
     * @summary GetProductByID
     * @request GET:/api/v1/products/{product_id}
     */
    v1ProductsDetail: (productId: string, params: RequestParams = {}) =>
      this.request<
        RenderHTTPResponse & {
          data?: ModelsProduct;
        },
        any
      >({
        path: `/api/v1/products/${productId}`,
        method: 'GET',
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description ProductPaymentIntent
     *
     * @tags Products
     * @name V1ProductsPaymentsIntentCreate
     * @summary ProductPaymentIntent
     * @request POST:/api/v1/products/{product_id}/payments/intent
     */
    v1ProductsPaymentsIntentCreate: (
      productId: string,
      query?: {
        /** Geo country for Apple Pay */
        geo_country?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        RenderHTTPResponse & {
          data?: EntitiesMerchantData;
        },
        any
      >({
        path: `/api/v1/products/${productId}/payments/intent`,
        method: 'POST',
        query: query,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description ProductPaymentPaypal
     *
     * @tags Products
     * @name V1ProductsPaymentsPaypalCreate
     * @summary ProductPaymentPaypal
     * @request POST:/api/v1/products/{product_id}/payments/paypal
     */
    v1ProductsPaymentsPaypalCreate: (
      productId: string,
      params: RequestParams = {},
    ) =>
      this.request<
        RenderHTTPResponse & {
          data?: EntitiesInitPaymentPaypalResponse;
        },
        any
      >({
        path: `/api/v1/products/${productId}/payments/paypal`,
        method: 'POST',
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description GetDownsellProducts
     *
     * @tags Products
     * @name V1ProductsDownsellList
     * @summary GetDownsellProducts
     * @request GET:/api/v1/products/downsell
     */
    v1ProductsDownsellList: (params: RequestParams = {}) =>
      this.request<
        RenderHTTPResponse & {
          data?: ModelsProduct;
        },
        any
      >({
        path: `/api/v1/products/downsell`,
        method: 'GET',
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Get all professional areas
     *
     * @tags professional_areas
     * @name V1ProfessionalAreasList
     * @summary Get all professional areas
     * @request GET:/api/v1/professional-areas
     */
    v1ProfessionalAreasList: (params: RequestParams = {}) =>
      this.request<
        RenderHTTPResponse & {
          data?: ModelsProfessionalArea[];
        },
        any
      >({
        path: `/api/v1/professional-areas`,
        method: 'GET',
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Get profile
     *
     * @tags profile
     * @name V1ProfileList
     * @summary Get profile
     * @request GET:/api/v1/profile
     */
    v1ProfileList: (params: RequestParams = {}) =>
      this.request<
        RenderHTTPResponse & {
          data?: HandlersGetProfileResponse;
        },
        any
      >({
        path: `/api/v1/profile`,
        method: 'GET',
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Update profile
     *
     * @tags profile
     * @name V1ProfileUpdate
     * @summary Update profile
     * @request PUT:/api/v1/profile
     */
    v1ProfileUpdate: (
      body: ModelsUserUpdateFields,
      params: RequestParams = {},
    ) =>
      this.request<
        RenderHTTPResponse & {
          data?: ModelsUser;
        },
        any
      >({
        path: `/api/v1/profile`,
        method: 'PUT',
        body: body,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description DeleteAvatar
     *
     * @tags avatars
     * @name V1ProfileAvatarDelete
     * @summary DeleteAvatar
     * @request DELETE:/api/v1/profile/avatar
     */
    v1ProfileAvatarDelete: (params: RequestParams = {}) =>
      this.request<RenderHTTPResponse, any>({
        path: `/api/v1/profile/avatar`,
        method: 'DELETE',
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description UploadAvatar
     *
     * @tags avatars
     * @name V1ProfileAvatarCreate
     * @summary UploadAvatar
     * @request POST:/api/v1/profile/avatar
     */
    v1ProfileAvatarCreate: (
      data: {
        /** file */
        file: File;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        RenderHTTPResponse & {
          data?: HandlersUploadAvatarResponse;
        },
        any
      >({
        path: `/api/v1/profile/avatar`,
        method: 'POST',
        body: data,
        type: ContentType.FormData,
        format: 'json',
        ...params,
      }),

    /**
     * @description Insert Brand Voice test
     *
     * @tags profile
     * @name V1ProfileTestsBrandVoiceCreate
     * @summary Insert Brand Voice test
     * @request POST:/api/v1/profile/tests/brand-voice
     */
    v1ProfileTestsBrandVoiceCreate: (
      body: HandlersInsertBrandVoiceTestRequest,
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/api/v1/profile/tests/brand-voice`,
        method: 'POST',
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Get profile usage statistics
     *
     * @tags profile
     * @name V1ProfileUsageStatisticsList
     * @summary Get profile usage statistics
     * @request GET:/api/v1/profile/usage_statistics
     */
    v1ProfileUsageStatisticsList: (params: RequestParams = {}) =>
      this.request<
        RenderHTTPResponse & {
          data?: ModelsProfileUsageStatistics;
        },
        any
      >({
        path: `/api/v1/profile/usage_statistics`,
        method: 'GET',
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Improve a prompt
     *
     * @tags Prompt
     * @name V1PromptImproveCreate
     * @summary Improve a prompt
     * @request POST:/api/v1/prompt/improve
     */
    v1PromptImproveCreate: (
      prompt: HandlersPromptImproveRequest,
      params: RequestParams = {},
    ) =>
      this.request<
        RenderHTTPResponse & {
          data?: HandlersPromptImproveResponse;
        },
        any
      >({
        path: `/api/v1/prompt/improve`,
        method: 'POST',
        body: prompt,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Prompt simple
     *
     * @tags chats
     * @name V1PromptSimpleCreate
     * @summary Prompt simple
     * @request POST:/api/v1/prompt/simple
     */
    v1PromptSimpleCreate: (
      body: HandlersPromptSimpleRequest,
      params: RequestParams = {},
    ) =>
      this.request<HandlersSimplePromptResponse, any>({
        path: `/api/v1/prompt/simple`,
        method: 'POST',
        body: body,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Subscription
     * @name V1SubscriptionList
     * @summary Get current subscription
     * @request GET:/api/v1/subscription
     */
    v1SubscriptionList: (params: RequestParams = {}) =>
      this.request<
        RenderHTTPResponse & {
          data?: ModelsUserSubscriptionSwagger;
        },
        any
      >({
        path: `/api/v1/subscription`,
        method: 'GET',
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Subscription
     * @name V1SubscriptionCancelCreate
     * @summary Cancel current subscription
     * @request POST:/api/v1/subscription/cancel
     */
    v1SubscriptionCancelCreate: (
      body: HandlersCancelCurrentSubscriptionRequest,
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/api/v1/subscription/cancel`,
        method: 'POST',
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Subscription
     * @name V1SubscriptionPauseDelete
     * @summary Remove pause current subscription
     * @request DELETE:/api/v1/subscription/pause
     */
    v1SubscriptionPauseDelete: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/v1/subscription/pause`,
        method: 'DELETE',
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Subscription
     * @name V1SubscriptionPauseCreate
     * @summary Pause current subscription
     * @request POST:/api/v1/subscription/pause
     */
    v1SubscriptionPauseCreate: (
      body: HandlersPauseCurrentSubscriptionRequest,
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/api/v1/subscription/pause`,
        method: 'POST',
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Subscription
     * @name V1SubscriptionRestoreCreate
     * @summary Restore current subscription
     * @request POST:/api/v1/subscription/restore
     */
    v1SubscriptionRestoreCreate: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/v1/subscription/restore`,
        method: 'POST',
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Get tool categories
     *
     * @tags tools
     * @name V1ToolCategoriesList
     * @summary Get tool categories
     * @request GET:/api/v1/tool-categories
     */
    v1ToolCategoriesList: (
      query?: {
        /** Page */
        page?: number;
        /** Per page */
        per_page?: number;
        /** Sort by */
        sort_by?: string;
        /** Sort type */
        sort_type?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        RenderHTTPResponse & {
          data?: ModelsToolCategory[];
          meta?: ModelsTotal;
        },
        any
      >({
        path: `/api/v1/tool-categories`,
        method: 'GET',
        query: query,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Get tools
     *
     * @tags tools
     * @name V1ToolsList
     * @summary Get tools
     * @request GET:/api/v1/tools
     */
    v1ToolsList: (params: RequestParams = {}) =>
      this.request<
        RenderHTTPResponse & {
          data?: ModelsPresenterTool[];
        },
        any
      >({
        path: `/api/v1/tools`,
        method: 'GET',
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Get tools by filters
     *
     * @tags tools
     * @name V1ToolsByFiltersList
     * @summary Get tools by filters
     * @request GET:/api/v1/tools-by-filters
     */
    v1ToolsByFiltersList: (
      query?: {
        /** Category IDs */
        category_ids?: string;
        /** Tags */
        tags?: string;
        /** Page */
        page?: number;
        /** Per page */
        per_page?: number;
        /** Sort by */
        sort_by?: string;
        /** Sort type */
        sort_type?: string;
        /** Search fields */
        search_fields?: string;
        /** Search text */
        search_text?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        RenderHTTPResponse & {
          data?: ModelsToolWithCategory[];
          meta?: ModelsTotal;
        },
        any
      >({
        path: `/api/v1/tools-by-filters`,
        method: 'GET',
        query: query,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Get tool by ID
     *
     * @tags tools
     * @name V1ToolsDetail
     * @summary Get tool by ID
     * @request GET:/api/v1/tools/{tool_id}
     */
    v1ToolsDetail: (toolId: string, params: RequestParams = {}) =>
      this.request<
        RenderHTTPResponse & {
          data?: ModelsToolPresenter;
        },
        any
      >({
        path: `/api/v1/tools/${toolId}`,
        method: 'GET',
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Handler Tool Execution Events Chunked
     *
     * @tags tools
     * @name V1ToolsExecuteStreamChunkedCreate
     * @summary Handler Tool Execution Events Chunked
     * @request POST:/api/v1/tools/{tool_id}/execute/stream/chunked
     */
    v1ToolsExecuteStreamChunkedCreate: (
      toolId: string,
      body: ModelsToolUserInput,
      params: RequestParams = {},
    ) =>
      this.request<HandlersToolEventResponse, any>({
        path: `/api/v1/tools/${toolId}/execute/stream/chunked`,
        method: 'POST',
        body: body,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Delete Tool Executions
     *
     * @tags tools
     * @name V1ToolsExecutionsDelete
     * @summary Delete Tool Executions
     * @request DELETE:/api/v1/tools/{tool_id}/executions
     */
    v1ToolsExecutionsDelete: (toolId: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/v1/tools/${toolId}/executions`,
        method: 'DELETE',
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Get Tool Executions
     *
     * @tags tools
     * @name V1ToolsExecutionsDetail
     * @summary Get Tool Executions
     * @request GET:/api/v1/tools/{tool_id}/executions
     */
    v1ToolsExecutionsDetail: (
      toolId: string,
      query?: {
        /** Page */
        page?: number;
        /** Per page */
        per_page?: number;
        /** Sort by */
        sort_by?: string;
        /** Sort type */
        sort_type?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        RenderHTTPResponse & {
          data?: ModelsToolExecutionResponse[];
          meta?: ModelsTotal;
        },
        any
      >({
        path: `/api/v1/tools/${toolId}/executions`,
        method: 'GET',
        query: query,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Get Tool Execution Result
     *
     * @tags tools
     * @name V1ToolsExecutionsDetail2
     * @summary Get Tool Execution Result
     * @request GET:/api/v1/tools/{tool_id}/executions/{execution_id}
     * @originalName v1ToolsExecutionsDetail
     * @duplicate
     */
    v1ToolsExecutionsDetail2: (
      toolId: string,
      executionId: string,
      params: RequestParams = {},
    ) =>
      this.request<
        RenderHTTPResponse & {
          data?: ModelsToolExecution;
        },
        any
      >({
        path: `/api/v1/tools/${toolId}/executions/${executionId}`,
        method: 'GET',
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Update Tool Execution Result
     *
     * @tags tools
     * @name V1ToolsExecutionsResultsUpdate
     * @summary Update Tool Execution Result
     * @request PUT:/api/v1/tools/{tool_id}/executions/{execution_id}/results/{result_id}
     */
    v1ToolsExecutionsResultsUpdate: (
      executionId: string,
      resultId: string,
      toolId: string,
      body: HandlersUpdateTooleExecutionResultRequest,
      params: RequestParams = {},
    ) =>
      this.request<
        RenderHTTPResponse & {
          data?: ModelsToolExecutionResponse;
        },
        any
      >({
        path: `/api/v1/tools/${toolId}/executions/${executionId}/results/${resultId}`,
        method: 'PUT',
        body: body,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Ai Edit Stream
     *
     * @tags tools
     * @name V1ToolsExecutionsResultsAiEditCreate
     * @summary Ai Edit Stream
     * @request POST:/api/v1/tools/{tool_id}/executions/{execution_id}/results/{result_id}/ai_edit
     */
    v1ToolsExecutionsResultsAiEditCreate: (
      toolId: string,
      executionId: string,
      resultId: string,
      body: HandlersAiEditRequest,
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/api/v1/tools/${toolId}/executions/${executionId}/results/${resultId}/ai_edit`,
        method: 'POST',
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Set Ai Edit as copied
     *
     * @tags tools
     * @name V1ToolsExecutionsResultsAiEditMarkCopiedCreate
     * @summary Set Ai Edit as copied
     * @request POST:/api/v1/tools/{tool_id}/executions/{execution_id}/results/{result_id}/ai_edit/{ai_edit_id}/mark_copied
     */
    v1ToolsExecutionsResultsAiEditMarkCopiedCreate: (
      aiEditId: string,
      toolId: string,
      executionId: string,
      resultId: string,
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/api/v1/tools/${toolId}/executions/${executionId}/results/${resultId}/ai_edit/${aiEditId}/mark_copied`,
        method: 'POST',
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Set User Ai Edit Reaction
     *
     * @tags tools
     * @name V1ToolsExecutionsResultsAiEditReactionCreate
     * @summary Set User Ai Edit Reaction
     * @request POST:/api/v1/tools/{tool_id}/executions/{execution_id}/results/{result_id}/ai_edit/{ai_edit_id}/reaction
     */
    v1ToolsExecutionsResultsAiEditReactionCreate: (
      aiEditId: string,
      toolId: string,
      executionId: string,
      resultId: string,
      body: HandlersToolExecutionReactionRequest,
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/api/v1/tools/${toolId}/executions/${executionId}/results/${resultId}/ai_edit/${aiEditId}/reaction`,
        method: 'POST',
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Set Tool Execution Result as copied
     *
     * @tags tools
     * @name V1ToolsExecutionsResultsMarkCopiedCreate
     * @summary Set Tool Execution Result as copied
     * @request POST:/api/v1/tools/{tool_id}/executions/{execution_id}/results/{result_id}/mark_copied
     */
    v1ToolsExecutionsResultsMarkCopiedCreate: (
      toolId: string,
      executionId: string,
      resultId: string,
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/api/v1/tools/${toolId}/executions/${executionId}/results/${resultId}/mark_copied`,
        method: 'POST',
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Set User Execution Reaction
     *
     * @tags tools
     * @name V1ToolsExecutionsResultsReactionCreate
     * @summary Set User Execution Reaction
     * @request POST:/api/v1/tools/{tool_id}/executions/{execution_id}/results/{result_id}/reaction
     */
    v1ToolsExecutionsResultsReactionCreate: (
      toolId: string,
      executionId: string,
      resultId: string,
      body: HandlersToolExecutionReactionRequest,
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/api/v1/tools/${toolId}/executions/${executionId}/results/${resultId}/reaction`,
        method: 'POST',
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Generate more like this
     *
     * @tags tools
     * @name V1ToolsExecutionsResultsGenerateMoreCreate
     * @summary Generate more like this
     * @request POST:/api/v1/tools/{tool_id}/executions/results/{result_id}/generate-more
     */
    v1ToolsExecutionsResultsGenerateMoreCreate: (
      toolId: string,
      executionId: string,
      resultId: string,
      params: RequestParams = {},
    ) =>
      this.request<
        RenderHTTPResponse & {
          data?: ModelsToolExecutionResponse;
        },
        any
      >({
        path: `/api/v1/tools/${toolId}/executions/results/${resultId}/generate-more`,
        method: 'POST',
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags feedback
     * @name V1ToolsFeedbacksCreate
     * @summary Create tool feedback
     * @request POST:/api/v1/tools/{tool_id}/feedbacks
     */
    v1ToolsFeedbacksCreate: (
      toolId: string,
      body: HandlersCreateToolFeedbackRequest,
      params: RequestParams = {},
    ) =>
      this.request<
        RenderHTTPResponse & {
          data?: ModelsToolFeedback;
        },
        any
      >({
        path: `/api/v1/tools/${toolId}/feedbacks`,
        method: 'POST',
        body: body,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags feedback
     * @name V1ToolsFeedbacksUpdate
     * @summary Update tool feedback
     * @request PUT:/api/v1/tools/{tool_id}/feedbacks/{feedback_id}
     */
    v1ToolsFeedbacksUpdate: (
      feedbackId: string,
      toolId: string,
      body: HandlersUpdateToolFeedbackRequest,
      params: RequestParams = {},
    ) =>
      this.request<
        RenderHTTPResponse & {
          data?: ModelsToolFeedback;
        },
        any
      >({
        path: `/api/v1/tools/${toolId}/feedbacks/${feedbackId}`,
        method: 'PUT',
        body: body,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Generate AAG Article
     *
     * @tags tools
     * @name V1ToolsAdvancedArticleGeneratorGenerateArticleCreate
     * @summary Generate AAG Article
     * @request POST:/api/v1/tools/advanced_article_generator/generate-article
     */
    v1ToolsAdvancedArticleGeneratorGenerateArticleCreate: (
      body: HandlersGenerateExtendedArticleRequest,
      params: RequestParams = {},
    ) =>
      this.request<
        RenderHTTPResponse & {
          data?: ModelsArticleResponse;
        },
        any
      >({
        path: `/api/v1/tools/advanced_article_generator/generate-article`,
        method: 'POST',
        body: body,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Generate AAG Outline
     *
     * @tags tools
     * @name V1ToolsAdvancedArticleGeneratorGenerateOutlineCreate
     * @summary Generate AAG Outline
     * @request POST:/api/v1/tools/advanced_article_generator/generate-outline
     */
    v1ToolsAdvancedArticleGeneratorGenerateOutlineCreate: (
      body: HandlersGenerateArticleOutlineRequest,
      params: RequestParams = {},
    ) =>
      this.request<
        RenderHTTPResponse & {
          data?: ModelsArticleOutline;
        },
        any
      >({
        path: `/api/v1/tools/advanced_article_generator/generate-outline`,
        method: 'POST',
        body: body,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Get Last AAG Results
     *
     * @tags tools
     * @name V1ToolsAdvancedArticleGeneratorLastResultsList
     * @summary Get Last AAG Results
     * @request GET:/api/v1/tools/advanced_article_generator/last-results
     */
    v1ToolsAdvancedArticleGeneratorLastResultsList: (
      params: RequestParams = {},
    ) =>
      this.request<
        RenderHTTPResponse & {
          data?: ModelsLastAAGResults;
        },
        any
      >({
        path: `/api/v1/tools/advanced_article_generator/last-results`,
        method: 'GET',
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Regenerate AAG Outline Section
     *
     * @tags tools
     * @name V1ToolsAdvancedArticleGeneratorRegenerateOutlineSectionCreate
     * @summary Regenerate AAG Outline Section
     * @request POST:/api/v1/tools/advanced_article_generator/regenerate-outline-section
     */
    v1ToolsAdvancedArticleGeneratorRegenerateOutlineSectionCreate: (
      body: HandlersRegenerateAAGOutlineSectionRequest,
      params: RequestParams = {},
    ) =>
      this.request<
        RenderHTTPResponse & {
          data?: ModelsToolExecution;
        },
        any
      >({
        path: `/api/v1/tools/advanced_article_generator/regenerate-outline-section`,
        method: 'POST',
        body: body,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Update AAG Outline
     *
     * @tags tools
     * @name V1ToolsAdvancedArticleGeneratorUpdateOutlineCreate
     * @summary Update AAG Outline
     * @request POST:/api/v1/tools/advanced_article_generator/update-outline
     */
    v1ToolsAdvancedArticleGeneratorUpdateOutlineCreate: (
      body: HandlersGenerateExtendedArticleRequest,
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/api/v1/tools/advanced_article_generator/update-outline`,
        method: 'POST',
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags feedback
     * @name V1ToolsFeedbacksCreate2
     * @summary Create tool board feedback
     * @request POST:/api/v1/tools/feedbacks
     * @originalName v1ToolsFeedbacksCreate
     * @duplicate
     */
    v1ToolsFeedbacksCreate2: (
      body: HandlersCreateToolBoardFeedbackRequest,
      params: RequestParams = {},
    ) =>
      this.request<
        RenderHTTPResponse & {
          data?: ModelsToolBoardFeedback;
        },
        any
      >({
        path: `/api/v1/tools/feedbacks`,
        method: 'POST',
        body: body,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Generate Image
     *
     * @tags tools
     * @name V1ToolsImageGeneratorGenerateCreate
     * @summary Generate Image
     * @request POST:/api/v1/tools/image-generator/generate
     */
    v1ToolsImageGeneratorGenerateCreate: (
      body: HandlersImageGenerationRequest,
      params: RequestParams = {},
    ) =>
      this.request<
        RenderHTTPResponse & {
          data?: ModelsImageGenerationPresenter;
        },
        any
      >({
        path: `/api/v1/tools/image-generator/generate`,
        method: 'POST',
        body: body,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Delete Image Generations
     *
     * @tags tools
     * @name V1ToolsImageGeneratorGenerationsDelete
     * @summary Delete Image Generations
     * @request DELETE:/api/v1/tools/image-generator/generations
     */
    v1ToolsImageGeneratorGenerationsDelete: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/v1/tools/image-generator/generations`,
        method: 'DELETE',
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Get Image Generations
     *
     * @tags tools
     * @name V1ToolsImageGeneratorGenerationsList
     * @summary Get Image Generations
     * @request GET:/api/v1/tools/image-generator/generations
     */
    v1ToolsImageGeneratorGenerationsList: (
      query?: {
        /** Page */
        page?: number;
        /** Per page */
        per_page?: number;
        /** Sort by */
        sort_by?: string;
        /** Sort type */
        sort_type?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        RenderHTTPResponse & {
          data?: ModelsImageGenerationPresenter[];
          meta?: ModelsTotal;
        },
        any
      >({
        path: `/api/v1/tools/image-generator/generations`,
        method: 'GET',
        query: query,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Image Generation Reaction
     *
     * @tags tools
     * @name V1ToolsImageGeneratorGenerationsReactionCreate
     * @summary Image Generation Reaction
     * @request POST:/api/v1/tools/image-generator/generations/{image_generation_id}/reaction
     */
    v1ToolsImageGeneratorGenerationsReactionCreate: (
      imageGenerationId: string,
      body: HandlersSetImageGenerationReactionRequest,
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/api/v1/tools/image-generator/generations/${imageGenerationId}/reaction`,
        method: 'POST',
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Image Generation Prompt Improve
     *
     * @tags tools
     * @name V1ToolsImageGeneratorImprovePromptCreate
     * @summary Image Generation Prompt Improve
     * @request POST:/api/v1/tools/image-generator/improve-prompt
     */
    v1ToolsImageGeneratorImprovePromptCreate: (
      body: HandlersImageGenerationPromptImproveRequest,
      params: RequestParams = {},
    ) =>
      this.request<
        RenderHTTPResponse & {
          data?: HandlersPromptImproveResponse;
        },
        any
      >({
        path: `/api/v1/tools/image-generator/improve-prompt`,
        method: 'POST',
        body: body,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Get tool by slug
     *
     * @tags tools
     * @name V1ToolsSlugDetail
     * @summary Get tool by slug
     * @request GET:/api/v1/tools/slug/{slug}
     */
    v1ToolsSlugDetail: (slug: string, params: RequestParams = {}) =>
      this.request<
        RenderHTTPResponse & {
          data?: ModelsToolPresenter;
        },
        any
      >({
        path: `/api/v1/tools/slug/${slug}`,
        method: 'GET',
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Get tools usage statistics
     *
     * @tags tools
     * @name V1ToolsUsageStatisticsList
     * @summary Get tools usage statistics
     * @request GET:/api/v1/tools/usage_statistics
     */
    v1ToolsUsageStatisticsList: (params: RequestParams = {}) =>
      this.request<
        RenderHTTPResponse & {
          data?: Record<string, number>;
        },
        any
      >({
        path: `/api/v1/tools/usage_statistics`,
        method: 'GET',
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Get ZeroGPT AIDetector
     *
     * @tags zerogpt
     * @name V1ZerogptAiDetectorList
     * @summary Get ZeroGPT AIDetector
     * @request GET:/api/v1/zerogpt/ai-detector
     */
    v1ZerogptAiDetectorList: (params: RequestParams = {}) =>
      this.request<
        RenderHTTPResponse & {
          data?: ModelsAiDetectorTool;
        },
        any
      >({
        path: `/api/v1/zerogpt/ai-detector`,
        method: 'GET',
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description ZeroGPT AIDetector
     *
     * @tags zerogpt
     * @name V1ZerogptAiDetectorCreate
     * @summary ZeroGPT AIDetector
     * @request POST:/api/v1/zerogpt/ai-detector
     */
    v1ZerogptAiDetectorCreate: (
      input: HandlersZeroGPTAIDetectorRequest,
      params: RequestParams = {},
    ) =>
      this.request<
        RenderHTTPResponse & {
          data?: ModelsZeroGPTAIDetectPresenter;
        },
        any
      >({
        path: `/api/v1/zerogpt/ai-detector`,
        method: 'POST',
        body: input,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Delete ZeroGPT AIDetector Executions
     *
     * @tags zerogpt
     * @name V1ZerogptAiDetectorExecutionsDelete
     * @summary Delete ZeroGPT AIDetector Executions
     * @request DELETE:/api/v1/zerogpt/ai-detector/executions
     */
    v1ZerogptAiDetectorExecutionsDelete: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/v1/zerogpt/ai-detector/executions`,
        method: 'DELETE',
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Get ZeroGPT AIDetector Executions
     *
     * @tags zerogpt
     * @name V1ZerogptAiDetectorExecutionsList
     * @summary Get ZeroGPT AIDetector Executions
     * @request GET:/api/v1/zerogpt/ai-detector/executions
     */
    v1ZerogptAiDetectorExecutionsList: (
      query?: {
        /** Page */
        page?: number;
        /** Per page */
        per_page?: number;
        /** Sort by */
        sort_by?: string;
        /** Sort type */
        sort_type?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        RenderHTTPResponse & {
          data?: ModelsZeroGPTAIDetect[];
          meta?: ModelsTotal;
        },
        any
      >({
        path: `/api/v1/zerogpt/ai-detector/executions`,
        method: 'GET',
        query: query,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Copy ZeroGPT AIDetector Execution Result
     *
     * @tags zerogpt
     * @name V1ZerogptAiDetectorExecutionsCopyCreate
     * @summary Copy ZeroGPT AIDetector Execution Result
     * @request POST:/api/v1/zerogpt/ai-detector/executions/{execution_id}/copy
     */
    v1ZerogptAiDetectorExecutionsCopyCreate: (
      executionId: string,
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/api/v1/zerogpt/ai-detector/executions/${executionId}/copy`,
        method: 'POST',
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Set ZeroGPT AIDetector Execution Reaction
     *
     * @tags zerogpt
     * @name V1ZerogptAiDetectorExecutionsReactionCreate
     * @summary Set ZeroGPT AIDetector Execution Reaction
     * @request POST:/api/v1/zerogpt/ai-detector/executions/{execution_id}/reaction
     */
    v1ZerogptAiDetectorExecutionsReactionCreate: (
      executionId: string,
      input: HandlersZeroGPTAIDetectReactionRequest,
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/api/v1/zerogpt/ai-detector/executions/${executionId}/reaction`,
        method: 'POST',
        body: input,
        type: ContentType.Json,
        ...params,
      }),
  };
  health = {
    /**
     * @description Health check
     *
     * @tags health
     * @name HealthList
     * @summary Health check
     * @request GET:/health
     */
    healthList: (params: RequestParams = {}) =>
      this.request<HandlersHealthCheckResponse, any>({
        path: `/health`,
        method: 'GET',
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  };
}
